import Wrapper from './_views/Wrapper.vue';

const parentRouteConfig = {
  name: 'Machine Categories',
  to: {
    name: 'machineCategories',
    query: {
      suffix: '',
    },
  },
};

export default [
  {
    path: '/machine-categories',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'machineCategories',
        component: () => import(/* webpackChunkName: "machineCategories" */ './_views/DsbMachineCategories.vue'),
        meta: {
          title: 'Machine Categories',
          navigation: {
            name: 'Machine Categories',
            position: 4,
            icon: 'view_agenda',
            to: {
              name: 'machineCategories',
              query: {
                suffix: '',
              },
            },
          },
        },
      },
      {
        path: ':id/history',
        name: 'machineCategoryHistory',
        component: () => import(/* webpackChunkName: "machineCategoryHistory" */ './_views/DsbMachineCategoryHistory.vue'),
        meta: {
          title: 'Machine Category History',
          parent: parentRouteConfig,
        },
      },
    ],
  },
];
