<template>
  <v-icon :color="state ? 'success' : 'error'">
    {{ state ? 'done' : 'close' }}
  </v-icon>
</template>

<script>
export default {
  name: 'DsbStateIcon',

  props: {
    state: Boolean,
  },
};
</script>
