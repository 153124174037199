import { AppLocationsApiService } from '@services/api';
import { AppLocationsLocalStorageService } from '@services/localStorage';

import { MutationTypes, ActionTypes } from '@store/types/appLocations.types';

export default {
  namespaced: true,

  state: {
    locations: AppLocationsLocalStorageService.getLocations(),
    suffix: AppLocationsLocalStorageService.getLocationSuffix(),
  },

  mutations: {
    [MutationTypes.SET_LOCATIONS](state, payload) {
      state.locations = payload;
      AppLocationsLocalStorageService.setLocations(JSON.stringify(payload));
    },

    [MutationTypes.SET_SUFFIX](state, payload) {
      state.suffix = payload;
      AppLocationsLocalStorageService.setLocationSuffix(payload);
    },
  },

  actions: {
    async [ActionTypes.GET_LOCATIONS]({ commit, state: { suffix: currentSuffix } }, { suffix }) {
      const data = await AppLocationsApiService.getLocations();
      const currentLocation = suffix || currentSuffix;
      const [firstLocationData = {}] = data;
      const filteredLocationData = data
        .find((location) => location.suffix === currentLocation) || {};

      commit(MutationTypes.SET_LOCATIONS, data);
      commit(MutationTypes.SET_SUFFIX, filteredLocationData.suffix || firstLocationData.suffix || '');
    },
  },
};
