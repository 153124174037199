export const parsePropByType = ({ prop, type, parser = () => {} }) => {
  switch (type) {
    case 'number':
      return Number(prop);
    case 'boolean':
      return Boolean(prop);
    case 'booleanString':
      return JSON.parse(prop);
    case 'array':
      return Array.isArray(prop) ? prop : [prop];
    case 'custom':
      return parser(prop);
    default:
      return prop;
  }
};
