import { LocalStorageService } from './localStorage.service';

const TOKEN_ID = 'token';

export const AuthLocalStorageService = {
  setToken: (token) => LocalStorageService.setItem(TOKEN_ID, token),

  getToken: () => LocalStorageService.getItem(TOKEN_ID) || null,

  removeToken: () => LocalStorageService.removeItem(TOKEN_ID),
};
