export const namespace = 'profitContracts';

export const MutationTypes = {
  SET_CONTRACTS: 'SET_CONTRACTS',
  SET_CONTRACT_HISTORY: 'SET_CONTRACT_HISTORY',
};

export const ActionTypes = {
  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_CONTRACT_HISTORY: 'GET_CONTRACT_HISTORY',
};
