import { ApiServiceV1 } from '@services/api';

export const LocationsApiService = {
  async getLocations() {
    const { data } = await ApiServiceV1.get('location');
    return data || [];
  },

  async getLocation({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/setting`);

    return data || {};
  },

  updateLocation({ suffix, data }) {
    return ApiServiceV1.post(`${suffix}/setting`, data);
  },
};
