import store from '@store';

import { AuthNamespace } from '@store/types';

import Wrapper from './_views/Wrapper.vue';

const parentRouteConfig = {
  name: 'Rules',
  to: {
    name: 'rules',
    query: {
      suffix: '',
      pageNum: '1',
      status: 'Active',
    },
  },
};

export default [
  {
    path: '/rules',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'rules',
        component: () => import(/* webpackChunkName: "rules" */ './_views/DsbRules'),
        meta: {
          title: 'Rules',
          navigation: {
            position: 1,
            name: 'Rules',
            icon: 'assignment_turned_in',
            specialQueries: ['suffix'],
            to: {
              name: 'rules',
              query: {
                suffix: '',
                pageNum: '1',
                status: 'Active',
              },
            },
          },
        },
      },

      {
        path: 'edit/:id',
        name: 'ruleEdit',
        component: () => import(/* webpackChunkName: "ruleDetails" */ './_views/DsbRule'),
        beforeEnter: ({ query, params }, from, next) => {
          if (store.getters[`${AuthNamespace}/hadAdminRole`]) {
            next();
          } else {
            next({ name: 'ruleDetails', query, params });
          }
        },
        meta: {
          title: 'Rule',
          parent: parentRouteConfig,
          showWithoutParentRouteParam: true,
        },
      },

      {
        path: 'details/:id',
        name: 'ruleDetails',
        component: () => import(/* webpackChunkName: "ruleDetails" */ './_views/DsbRule'),
        meta: {
          title: 'Rule',
          parent: parentRouteConfig,
          showWithoutParentRouteParam: true,
        },
      },

      {
        path: 'create',
        name: 'newRule',
        component: () => import(/* webpackChunkName: "ruleCreator" */ './_views/DsbRuleCreator.vue'),
        meta: {
          title: 'Create Rule',
          parent: parentRouteConfig,
          showWithoutParentRouteParam: true,
        },
      },

      {
        path: 'slice/:id',
        name: 'ruleSlice',
        component: () => import(/* webpackChunkName: "ruleSlice" */ './_views/DsbRuleSlice.vue'),
        meta: {
          title: 'Rule Slice',
          parent: parentRouteConfig,
          showWithoutParentRouteParam: true,
        },
      },

      {
        path: ':id/compare/:historyId',
        name: 'ruleHistoryComparer',
        component: () => import(/* webpackChunkName: "ruleSlice" */ './_views/DsbRuleHistoryComparer.vue'),
        meta: {
          title: 'Rule History Compare',
          customDisplayedParamKey: 'historyId',
          showWithoutParentRouteParam: true,
          customParentRouteInfo: [
            {
              name: 'Rule',
              disabled: true,
            },
            {
              _param: 'id',
              to: {
                name: 'ruleEdit',
                _params: ['id'],
                _queries: ['suffix'],
              },
            },
          ],
        },
      },
    ],
  },

  {
    path: '/',
    redirect: {
      name: 'rules',
      query: {
        suffix: '',
        pageNum: '1',
        status: 'Active',
      },
    },
  },
];
