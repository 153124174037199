import { AppLocationsNamespace } from '@store/types';

import { SubstratesApiService } from './_services/substrates.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    substrates: [],
    substratesTotalCount: 0,
  },

  mutations: {
    [MutationTypes.SET_SUBSTRATES](state, payload) {
      state.substrates = payload;
    },

    [MutationTypes.SET_SUBSTRATES_TOTAL_COUNT](state, payload) {
      state.substratesTotalCount = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_SUBSTRATES](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items, totalCount } = await SubstratesApiService.getSubstrates({
        suffix, ...params,
      });

      commit(MutationTypes.SET_SUBSTRATES, items || []);
      commit(MutationTypes.SET_SUBSTRATES_TOTAL_COUNT, totalCount);
    },
  },
};
