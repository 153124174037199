export default [
  {
    path: '/access-journal',
    name: 'accessJournal',
    component: () => import('./_views/DsbAccessJournal.vue'),
    meta: {
      title: 'Access Journal',
      navigation: {
        position: 5,
        name: 'Access Journal',
        icon: 'notes',
        specialQueries: ['suffix'],
        to: {
          name: 'accessJournal',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
