var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":_vm.closeOnClick,"nudge-left":"265px","allow-overflow":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"value":_vm.notificationsCount,"content":_vm.notificationsCount,"color":"error","overlap":""}},[_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"white"}},on),[_c('v-icon',[_vm._v(" notifications ")])],1)],1)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',{staticClass:"dsb-notifications-wrapper"},[_c('v-card-text',[_c('v-layout',{staticStyle:{"position":"absolute","margin-top":"12px","width":"100%","padding-right":"55px"},attrs:{"justify-end":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.isSyncLoading,"color":"success","small":"","icon":""},on:{"click":_vm.syncNotifications}},on),[_c('v-icon',[_vm._v(" sync ")])],1)]}}])},[_vm._v(" Sync notifications ")])],1),(_vm.notificationsCount)?_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"page-size":5,"show-paginator":""},scopedSlots:_vm._u([{key:"basketId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"info--text",attrs:{"to":{
              name: 'pressSheets',
              query: {
                suffix: _vm.suffix,
                pageNum: '1',
                basketId: item.basketId,
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(item.basketId)+" ")])]}},{key:"created",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDatesDifference(item.created).formatString({ second: false }))+" ago ")]}},{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"loading":_vm.$_checkItemInLoadingSpinners(item.id),"icon":"","color":"error"},on:{"click":function($event){return _vm.removeNotification(item.id)}}},[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,false,3298725528)}):_c('v-layout',{attrs:{"justify-center":""}},[_vm._v(" No notifications yet ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }