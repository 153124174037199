export const createRulePressSheetTemplate = () => ({
  id: 0,
  priority: 0,
  collated: false,
  pseudo: false,
  machineCategory: 0,
});

export const createRule = ({ withPressSheetTemplate = false } = {}) => ({
  minQuantity: 1,
  maxQuantity: 1,
  productType: '',
  signatureType: '',
  substrate: '',
  foilColorFront: '',
  foilColorBack: '',
  hoursBeforeTruck: 0,
  minSheets: 0,
  maxSheets: 0,
  itemsCount: 2,
  timeToTruck: 0,
  oneItemOnSheet: false,
  laminating: [],
  variableUv: [],
  contract: [],
  orientation: ['None'],
  roundedCorners: [],
  statistic: [],
  cover: [],
  foiling: [],
  pressSheetTemplates: withPressSheetTemplate ? [createRulePressSheetTemplate()] : [],
  pressSheetTemplateForRemaining: null,
});
