export const namespace = 'profitProducts';

export const MutationTypes = {
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_PRODUCTS_TOTAL_COUNT: 'SET_PRODUCTS_TOTAL_COUNT',
  SET_PRODUCT_HISTORY: 'SET_PRODUCT_HISTORY',
};

export const ActionTypes = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_HISTORY: 'GET_PRODUCT_HISTORY',
};
