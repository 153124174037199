export const createRuleSlice = () => ({
  id: 0,
  created: '',
  filter: {
    id: 0,
    status: [],
    orientation: [],
    laminating: [],
    variableUv: [],
    cover: [],
    foiling: [],
    foilColorFront: '',
    foilColorBack: '',
    minQuantity: 0,
    maxQuantity: 0,
    productType: '',
    pressSheetTemplates: [],
    substrate: '',
    hoursBeforeTruck: 0,
  },
  body: '',
});
