import { ApiServiceV1 } from './api.service';

export const AutocompleteApiService = {
  async getPressSheetsTemplatesAutocomplete({ suffix, query }) {
    const { data } = await ApiServiceV1.get(`${suffix}/template/autocomplete`, {
      params: {
        query,
      },
    });

    return data;
  },

  async getProductAutocomplete({ suffix, query }) {
    const { data } = await ApiServiceV1.get(`${suffix}/product/autocomplete`, {
      params: {
        query,
      },
    });

    return data;
  },

  async getSubstrateAutocomplete({ suffix, query }) {
    const { data } = await ApiServiceV1.get(`${suffix}/substrate/autocomplete`, {
      params: {
        query,
      },
    });

    return data;
  },

  async getMachineNameAutocomplete({ suffix, query }) {
    const { data } = await ApiServiceV1.get(`${suffix}/machine/autocomplete/name`, {
      params: {
        query,
      },
    });

    return data;
  },

  async getMachinePublicKeyAutocomplete({ suffix, query }) {
    const { data } = await ApiServiceV1.get(`${suffix}/machine/autocomplete/key`, {
      params: {
        query,
      },
    });

    return data;
  },
};
