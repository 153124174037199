import { saveAs } from 'file-saver';

export const XLSX_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV_FILE_TYPE = 'text/csv';

export const downloadExcelWithDisposition = async ({ headers, data }) => {
  const disposition = headers['content-disposition'];
  let filename;

  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  saveAs(new Blob([data], { type: XLSX_FILE_TYPE }), filename);

  return Promise.resolve();
};

const getFileNameFromDisposition = (headers, defaultFileName) => {
  const disposition = headers['content-disposition'];
  let filename;

  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename || defaultFileName;
};

export const downloadFileWithDisposition = ({
  headers, data, type, defaultFileName,
}) => {
  saveAs(new Blob([data], { type }), getFileNameFromDisposition(headers, defaultFileName));
};
