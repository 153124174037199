import { AppLocationsNamespace } from '@store/types';

import { PressSheetsTemplatesApiService } from './_services/pressSheetsTemplates.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    pressSheetsTemplates: [],
    pressSheetsTemplate: {},
    pressSheetsTemplatesTotalCount: 0,
  },

  mutations: {
    [MutationTypes.SET_PRESS_SHEETS_TEMPLATES](state, payload) {
      state.pressSheetsTemplates = payload;
    },

    [MutationTypes.SET_PRESS_SHEETS_TEMPLATE](state, payload) {
      state.pressSheetsTemplate = payload;
    },

    [MutationTypes.SET_PRESS_SHEETS_TEMPLATES_TOTAL_COUNT](state, payload) {
      state.pressSheetsTemplatesTotalCount = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_PRESS_SHEETS_TEMPLATES](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items, totalCount } = await PressSheetsTemplatesApiService.getPressSheetsTemplates({
        suffix, ...params,
      });

      commit(MutationTypes.SET_PRESS_SHEETS_TEMPLATES, items || []);
      commit(MutationTypes.SET_PRESS_SHEETS_TEMPLATES_TOTAL_COUNT, totalCount);
    },

    async [ActionTypes.GET_PRESS_SHEETS_TEMPLATE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_PRESS_SHEETS_TEMPLATE,
        await PressSheetsTemplatesApiService.getPressSheetsTemplate({ suffix, ...params }),
      );
    },
  },
};
