import { ApiServiceV1 } from '@services/api';

export const ContractsApiService = {
  async getContracts({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/contract`);

    return data || [];
  },

  async getContractHistory({ suffix, name }) {
    const { data } = await ApiServiceV1.get(`${suffix}/contract/${encodeURIComponent(name)}/history`);

    return data || [];
  },

  syncContracts({ suffix }) {
    return ApiServiceV1.post(`${suffix}/contract/sync`);
  },

  updateContractOrderWaitTime({
    suffix, name, days, hours,
  }) {
    return ApiServiceV1.post(`${suffix}/contract/${name}`, { days, hours });
  },
};
