import store from '@store';

import { AppLocationsNamespace } from '@store/types';

const NAVIGATION_GROUPS = [
  { name: 'Messages', key: 'messages', icon: 'email' },
  { name: 'ProFIT', key: 'profit', icon: 'work' },
];
const SPECIFY_QUERIES = [
  {
    key: 'suffix',
    getData: () => store.state[AppLocationsNamespace].suffix,
    items: [],
  },
];

const sortByPosition = (a, b) => {
  const { hasOwnProperty: has } = Object.prototype;
  if (has.call(a, 'position') && has.call(b, 'position')) {
    return a.position - b.position;
  }
  if (has.call(a, 'position')) {
    return -1;
  }
  if (has.call(b, 'position')) {
    return 1;
  }
  return 0;
};

const isValidRoute = ({ children, meta }) => children || (meta && meta.navigation);
const isValidSingleRoute = (route) => route.meta && !route.meta.group && route.meta.navigation;
const isValidGroupRoute = (route, key) => route.meta && route.meta.group === key;

const generateRouteConfig = (route) => {
  const { navigation } = route.meta;
  const { specialQueries: routeSpecialQueries = [] } = navigation;
  SPECIFY_QUERIES.forEach((opt) => {
    if (routeSpecialQueries.includes(opt.key)) {
      navigation.to.query = {
        ...navigation.to.query,
        [opt.key]: opt.getData(),
      };
    }
  });

  return navigation;
};

const generateSingleRoutes = (routes) => routes
  .filter(isValidSingleRoute)
  .map(generateRouteConfig)
  .sort(sortByPosition);

const generateGroupRoutes = (routes, $route) => NAVIGATION_GROUPS.map((g) => ({
  ...g,
  visible: $route.meta.group === g.key,
  group: routes
    .filter((route) => isValidGroupRoute(route, g.key))
    .map(generateRouteConfig)
    .sort(sortByPosition),
}));

export default (routes, $route) => {
  const VALID_ROUTES = routes.filter(isValidRoute);
  const NAVIGATION_ROUTES = [];
  const generateNavigationRoutes = (data) => data.forEach((route) => {
    if (route.children) {
      generateNavigationRoutes(route.children);
    } else if (route.meta && route.meta.navigation) {
      NAVIGATION_ROUTES.push(route);
    }
  });
  generateNavigationRoutes(VALID_ROUTES);
  return [
    ...generateSingleRoutes(NAVIGATION_ROUTES),
    ...generateGroupRoutes(NAVIGATION_ROUTES, $route),
  ];
};
