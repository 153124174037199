export const createStatistics = () => ({
  activeOrderItemsCount: 0,
  activeOrderFiltersCount: 0,
  activeMachinesCount: 0,
  lastAddedOrderItemAgo: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  lastCreatedPressSheetAgo: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
});
