export const createLocation = () => ({
  id: 0,
  name: '',
  routeSuffix: '',
  profitUrl: '',
  rabbitMqHost: '',
  rabbitMqPort: 0,
  rabbitMqUsername: '',
  rabbitMqPassword: '',
  rabbitMqVirtualHost: '',
  rabbitMqContentEncoding: '',
  rabbitMqPersistent: true,
  rabbitMqNewOrderRoute: '',
  rabbitMqUpdateOrderRoute: '',
  rabbitMqUpdateOrderItemRoute: '',
  rabbitMqChangeMachineStateRoute: '',
  rabbitMqDeletedPressSheetRoute: '',
  hoursToDisableOrders: 0,
  messagesMaxLifeHours: 0,
  loadStatisticPeriodHours: 0,
});
