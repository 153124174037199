import { parsePropByType } from '@helpers';

// route query params for application using
const defaultSystemQueryParams = ['suffix', 'pageNum'];

/**
 * Getter for initial states for search data
 * If user not set state value, define undefined value
 *
 * @param states {Array} array of strings or objects
 *
 * @returns {Object}
 */
const getSearchDataInitialStates = (states) => {
  const result = {};
  states.forEach((state) => {
    if (typeof state === 'string') {
      result[state] = undefined;
    } else {
      result[state.prop] = state.value;
    }
  });
  return result;
};

/**
 * Generated mixin for working with search component
 * This is basic configuration, you can modify some properties
 * For more information see documentation for VBlackerTheme Search component
 *
 * @param systemQueryParams {Array} - names of app query props,
 * includes to route query and not needed in searchData
 *
 * @param initialSearchDataFields {Array} - includes strings or objects with fields:
 * prop - property name, value - initial value
 *
 * @param searchFieldsConfig {Array} - config for drawing search component,
 * for more info read documentation for VBlackerTheme Search component
 *
 * @param customTypes { Object } - config for type parse, use values:
 *  number, boolean, booleanString, array
 *
 * @returns {Object} local named mixin for Vue component
 */
export function useSearch({
  systemQueryParams = defaultSystemQueryParams,
  initialSearchDataFields = [],
  searchFieldsConfig = [],
  customTypes = {},
} = {}) {
  return {
    data() {
      return {
        searchData: this.generateSearchData(this.$route),
      };
    },
    computed: {
      searchFieldsConfig() {
        return searchFieldsConfig.map((field) => ({ ...field, type: field.type || 'text' }));
      },
      $_routeQueryWithoutSystemParams() {
        const { query } = this.$route;
        const routeQueryKeys = Object.keys(query);
        const result = {};
        routeQueryKeys.forEach((key) => {
          if (!systemQueryParams.includes(key)) {
            result[key] = query[key];
          }
        });
        return result;
      },
    },
    watch: {
      $_routeQueryWithoutSystemParams: {
        handler(query) {
          Object.assign(this, {
            searchData: this.generateSearchData({ query }),
          });
        },
        deep: true,
      },
    },
    methods: {
      // if user not set this method
      generateSearchData({ query }) {
        const initialStates = getSearchDataInitialStates(initialSearchDataFields);
        const initialStatesKeys = Object.keys(initialStates);
        const result = {};
        initialStatesKeys.forEach((key) => {
          const { [key]: prop = initialStates[key] } = query;

          result[key] = prop === initialStates[key]
            ? prop
            : parsePropByType({
              prop,
              parser: customTypes[key],
              type: typeof customTypes[key] === 'function' ? 'custom' : customTypes[key],
            });
        });
        return result;
      },
      // can be reassign for customization
      parseSearchData(data) {
        const dataKeys = Object.keys(data);
        const result = {};
        dataKeys.forEach((key) => {
          if (key === 'date' && Array.isArray(data[key])) {
            [result.startDate, result.endDate] = data[key]; // resolve array dates prop
          } else if (data[key]) {
            result[key] = data[key];
          }
        });
        return result;
      },
      onSearch() {
        this.$_search();
      },
      onResetSearch() {
        this.$_resetSearch();
      },
      $_search() {
        const query = {};

        systemQueryParams.forEach((prop) => {
          if (Object.prototype.hasOwnProperty.call(this.$route.query, prop)) {
            query[prop] = this.$route.query[prop];
          }
        });

        if (this.$_setPage) {
          query.pageNum = '1';
        }

        this.$router.push({
          query: {
            ...query,
            ...this.parseSearchData(this.searchData),
          },
        }, null, () => this.$_fetchData());
      },
      $_resetSearch() {
        Object.assign(this, {
          searchData: { ...getSearchDataInitialStates(initialSearchDataFields) },
        });
        const query = {};

        systemQueryParams.forEach((prop) => {
          if (Object.prototype.hasOwnProperty.call(this.$route.query, prop)) {
            query[prop] = this.$route.query[prop];
          }
        });

        if (this.$_setPage) {
          query.pageNum = '1';
        }

        this.$router.push({ query }, null, () => this.$_fetchData());
      },
    },
  };
}
