export const namespace = 'dashboard';

export const MutationTypes = {
  SET_DASHBOARD_STATISTICS: 'SET_DASHBOARD_STATISTICS',
  SET_DASHBOARD_CHART: 'SET_DASHBOARD_CHART',
};

export const ActionTypes = {
  GET_DASHBOARD_STATISTICS: 'GET_DASHBOARD_STATISTICS',
  GET_DASHBOARD_CHART: 'GET_DASHBOARD_CHART',
};
