<template>
  <v-text-field
    v-model.trim="password"
    :rules="validator.getRulesByField('password')"
    :type="showPassword ? 'text' : 'password'"
    :label="label"
    v-on="$listeners"
  >
    <template #append>
      <v-btn
        icon
        small
        @mousedown.left="showPassword = !showPassword"
        @mouseup.left="showPassword = !showPassword"
        @mouseleave="showPassword = false"
      >
        <v-icon>
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import { Validator } from '@helpers';

const validator = Validator.createValidator({
  password: ['required'],
});

export default {
  name: 'DsbPasswordInput',
  model: {
    type: 'input',
    prop: 'value',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: 'Password',
    },
  },
  mixins: [
    Validator.useValidator(validator),
  ],
  data: () => ({ showPassword: false }),
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
