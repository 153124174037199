import { AppLocationsNamespace } from '@store/types';

import { createStatistics } from './_meta/statistics';

import { DashboardApiService } from './_services/dashboard.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    statistics: createStatistics(),
    chart: [],
  },

  mutations: {
    [MutationTypes.SET_DASHBOARD_STATISTICS](state, payload) {
      state.statistics = payload;
    },

    [MutationTypes.SET_DASHBOARD_CHART](state, payload) {
      state.chart = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_DASHBOARD_STATISTICS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_DASHBOARD_STATISTICS,
        await DashboardApiService.getDashboardStatistics({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_DASHBOARD_CHART](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_DASHBOARD_CHART,
        await DashboardApiService.getDashboardChart({ suffix, ...params }),
      );
    },
  },
};
