import { decodeJWT } from '@osvick/helpers';

import { AuthApiService } from '@services/api';
import { AuthLocalStorageService } from '@services/localStorage';

import { removeLastSlashFromUrl } from '@helpers';

import { AppNamespace, AppLocationsNamespace } from '@store/types';

import { MutationTypes, ActionTypes } from '@store/types/auth.types';

const APP_ADMIN_ROLE = 'dsb:admin';

const generateDefaultUMSReturnUrlWithLocationSuffix = (suffix) => `${
  window.location.origin
}/rules?suffix=${
  suffix || ''
}&pageNum=1&status=Active`;

export default {
  namespaced: true,

  state: {
    token: AuthLocalStorageService.getToken(),
    isAuthenticated: false,
    returnUrl: null,
  },

  mutations: {
    [MutationTypes.SET_AUTH](state, payload) {
      state.isAuthenticated = payload;
    },

    [MutationTypes.SET_TOKEN](state, payload) {
      state.token = payload;
      if (payload) {
        AuthLocalStorageService.setToken(payload);
      } else {
        AuthLocalStorageService.removeToken();
      }
    },

    [MutationTypes.SET_RETURN_URL](state, payload) {
      state.returnUrl = payload;
    },
  },

  actions: {
    async [ActionTypes.GENERATE_TOKEN_FROM_URL](
      { state: { token: TOKEN }, commit, dispatch },
      { query },
    ) {
      const q = JSON.parse(JSON.stringify(query));
      const { umToken: UM_TOKEN } = q;

      if (!UM_TOKEN && !TOKEN) {
        dispatch(ActionTypes.LOGOUT, { routeName: 'login' });
        return Promise.reject();
      }

      AuthLocalStorageService.setToken(UM_TOKEN || TOKEN);

      await AuthApiService.checkUserAuth();

      commit(MutationTypes.SET_AUTH, true);

      if (UM_TOKEN) {
        commit(MutationTypes.SET_TOKEN, UM_TOKEN);

        delete q.umToken;
        delete q.expires;
      }

      return Promise.resolve(q);
    },

    [ActionTypes.LOGIN]({
      state: { returnUrl },
      rootState: {
        [AppNamespace]: { userManagerUrl, clientId },
        [AppLocationsNamespace]: { suffix },
      },
    }) {
      window.location = `${
        removeLastSlashFromUrl(userManagerUrl)
      }/OuterAccount/Login?service=${
        clientId
      }&returnUrl=${
        encodeURIComponent(
          returnUrl || generateDefaultUMSReturnUrlWithLocationSuffix(suffix),
        )
      }`;
    },

    [ActionTypes.LOGOUT]({ commit }, { routeName } = {}) {
      commit(MutationTypes.SET_TOKEN, null);
      commit(MutationTypes.SET_AUTH, false);

      if (routeName !== 'login' && routeName !== 'forbidden') {
        commit(MutationTypes.SET_RETURN_URL, window.location.href);
      }
    },
  },

  getters: {
    hadAdminRole: ({ token }) => {
      const { roles = [] } = token ? decodeJWT(token) : {};

      return roles.some((role) => role.toLowerCase() === APP_ADMIN_ROLE);
    },
  },
};
