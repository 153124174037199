<template>
  <div id="app">
    <app-wrapper />
  </div>
</template>

<script>
import AppWrapper from '@components/layouts/AppWrapper';

export default {
  components: {
    AppWrapper,
  },
};
</script>
