import store from '@store';

import { AuthNamespace } from '@store/types';

import Wrapper from './_views/Wrapper.vue';

const parentRouteConfig = {
  name: 'Locations',
  to: {
    name: 'locations',
    query: {
      suffix: '',
    },
  },
};

export default [
  {
    path: '/locations',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'locations',
        component: () => import(/* webpackChunkName: "locations" */ './_views/DsbLocations.vue'),
        meta: {
          title: 'Locations',
        },
      },
      {
        path: ':suffix',
        name: 'location',
        component: () => import(/* webpackChunkName: "location" */ './_views/DsbLocation.vue'),
        beforeEnter: ({ query }, from, next) => {
          if (store.getters[`${AuthNamespace}/hadAdminRole`]) {
            next();
          } else {
            next({ name: 'locations', query });
          }
        },
        meta: {
          title: 'Location',
          parent: parentRouteConfig,
          customDisplayedParamKey: 'suffix',
        },
      },
    ],
  },
];
