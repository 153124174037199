const getRouteDisplayedParam = ($route) => {
  const { customDisplayedParamKey, customDisplayedValue } = $route.meta;

  if (customDisplayedValue) {
    return customDisplayedValue();
  }
  if (customDisplayedParamKey) {
    return $route.params[customDisplayedParamKey];
  }

  return $route.params.id;
};

export const MAIN_ROUTE_NAME = 'rules';

export default ($route, mainRouteConfig) => {
  const { name: currentRouteName, meta: currentRouteMeta = {} } = $route;

  if (currentRouteName === mainRouteConfig.name) {
    return [{
      name: mainRouteConfig.title,
      isCurrentLink: true,
      disabled: true,
      to: {},
    }];
  }

  const ROUTE_INFO_CONFIG = [];

  ROUTE_INFO_CONFIG.push({
    name: mainRouteConfig.title,
    to: mainRouteConfig.to,
  });

  if (currentRouteMeta.parent && !currentRouteMeta.showWithoutParentRouteParam) {
    ROUTE_INFO_CONFIG.push(
      {
        name: currentRouteMeta.parent.name,
        to: currentRouteMeta.parent.to,
        disabled: currentRouteMeta.parent.disabled,
      },
      {
        name: currentRouteMeta.title,
        isCurrentLink: true,
        disabled: true,
        to: {},
      },
    );

    if (!currentRouteMeta.hideWithParentRouteParam) {
      ROUTE_INFO_CONFIG.push({
        name: getRouteDisplayedParam($route),
        disabled: true,
        bold: true,
        to: {},
      });
    }

    return ROUTE_INFO_CONFIG;
  }

  if (currentRouteMeta.customParentRouteInfo) {
    /* eslint-disable no-underscore-dangle */
    ROUTE_INFO_CONFIG.push(
      ...currentRouteMeta.customParentRouteInfo.map((c) => {
        const r = JSON.parse(JSON.stringify(c));

        if (r._param) {
          r.name = $route.params[r._param];
        }

        if (r?.to?._params) {
          r.to.params = r.to._params.reduce((acc, param) => {
            acc[param] = $route.params[param];

            return acc;
          }, {});
        }

        if (r?.to?._queries) {
          r.to.query = r.to._queries.reduce((acc, query) => {
            acc[query] = $route.query[query];

            return acc;
          }, {});
        }

        if (!r.to) {
          r.to = {};
        }

        return r;
      }),
    );
    /* eslint-enable no-underscore-dangle */
  }

  ROUTE_INFO_CONFIG.push({
    name: currentRouteMeta.title,
    isCurrentLink: true,
    disabled: true,
    to: {},
  });

  if (currentRouteMeta.showWithoutParentRouteParam) {
    ROUTE_INFO_CONFIG.push({
      name: getRouteDisplayedParam($route),
      disabled: true,
      bold: true,
      to: {},
    });
  }

  return ROUTE_INFO_CONFIG;
};
