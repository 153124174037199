import { ApiServiceV1 } from '@services/api';

export const PressSheetsTemplatesApiService = {
  async getPressSheetsTemplates({
    suffix, name = null, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/template`, {
      params: {
        name, pageNum, pageSize,
      },
    });

    return data || {};
  },

  async getPressSheetsTemplate({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/template/${id}`);

    return data || {};
  },

  syncPressSheetsTemplates({ suffix }) {
    return ApiServiceV1.post(`${suffix}/template/sync`, {}, {
      headers: {
        'Content-Type': 'text/json',
      },
    });
  },
};
