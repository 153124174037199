<template>
  <a
    v-if="link"
    :href="link"
    target="_blank"
    class="info--text"
  >
    <slot />
  </a>
  <span v-else>
    <slot />
  </span>
</template>

<script>
export default {
  name: 'DsbLink',

  props: {
    link: '', // eslint-disable-line
  },
};
</script>
