import { ApiServiceV1 } from '@services/api';

export const OutputMessagesApiService = {
  async getOutputMessages({
    suffix, basketId = null, pressSheetId = null,
    state = null, type = null, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/output-message`, {
      params: {
        basketId, pressSheetId, state, type, pageNum, pageSize,
      },
    });

    return data || {};
  },

  async getOutputMessage({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/output-message/${id}`);

    return data || {};
  },
};
