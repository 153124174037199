import Vue from 'vue';
import VueRouter from 'vue-router';

import { AuthLocalStorageService } from '@services/localStorage';

import { SHORT_PROJECT_NAME } from '@config/application';
import { MAIN_ROUTE_NAME } from '@config/routeInformation';

import store from '@store';

import {
  AuthNamespace,
  AuthMutationTypes,
  AuthActionTypes,
  AppLocationsNamespace,
  AppLocationsMutationTypes,
  AppLocationsActionTypes,
} from '@store/types';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    components: {
      login: () => import('@components/layouts/AppLogin'),
    },
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/403',
    name: 'forbidden',
    meta: {
      title: 'Forbidden',
    },
  },
  {
    path: '/404',
    name: 'error404',
    meta: {
      title: 'Page not found',
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

// Automatic registration of routes from @modules
const requireModules = require.context(
  '@modules/',
  true,
  /^(((\.{1,2})|([a-zA-Z]+))\/)*([a-zA-Z]+\.)?(\b(routes)\b)?$/,
);
requireModules.keys().forEach((path) => {
  const route = requireModules(path).default;

  if (!route) {
    return;
  }

  if (Array.isArray(route)) {
    routes.push(...route);
  } else {
    routes.push(route);
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let isInitStep = true;
let isInitAppLoading = false;

const initializeApp = async ({ name: routeName, query: routeQuery }) => {
  isInitAppLoading = true;

  try {
    const generatedRouteQuery = await store.dispatch(
      `${AuthNamespace}/${AuthActionTypes.GENERATE_TOKEN_FROM_URL}`,
      { query: routeQuery },
    );

    await store.dispatch(
      `${AppLocationsNamespace}/${AppLocationsActionTypes.GET_LOCATIONS}`,
      { suffix: routeQuery.suffix },
    );

    generatedRouteQuery.suffix = generatedRouteQuery.suffix
      || store.state[AppLocationsNamespace].suffix
      || undefined;

    if (generatedRouteQuery.suffix) {
      store.commit(
        `${AppLocationsNamespace}/${AppLocationsMutationTypes.SET_SUFFIX}`,
        generatedRouteQuery.suffix,
      );
    }

    return Promise.resolve({
      query: routeName === 'login'
        ? {
          name: MAIN_ROUTE_NAME,
          query: {
            suffix: generatedRouteQuery.suffix,
            pageNum: '1',
          },
        }
        : generatedRouteQuery,
    });
  } catch (e) {
    return Promise.resolve({ isInvalidAuth: true });
  } finally {
    isInitStep = false;
    isInitAppLoading = false;
  }
};

router.beforeEach(async (to, from, next) => {
  if (isInitAppLoading) {
    return;
  }

  const logout = (route) => {
    if (route.name && route.name !== 'login' && route.name !== 'forbidden') {
      store.commit(
        `${AuthNamespace}/${AuthMutationTypes.SET_RETURN_URL}`,
        `${window.location.origin}${route.fullPath}`,
      );
    }

    document.title = `${to.meta.title} | ${SHORT_PROJECT_NAME}`;

    next(to.name !== 'login' && to.name !== 'forbidden' ? { name: 'login' } : undefined);
  };

  let query = null;

  if (isInitStep) {
    const { isInvalidAuth, query: q } = await initializeApp(to);

    if (isInvalidAuth) {
      logout(to);
      return;
    }
    if (q) {
      query = q;
    }
  }

  if (!AuthLocalStorageService.getToken()) {
    logout(to);
    return;
  }

  if (query) {
    next({ ...to, query });
    return;
  }

  if (to.name === 'error404' || to.name === 'forbidden') {
    next();
    return;
  }

  if (to.name === 'login') {
    next({
      name: MAIN_ROUTE_NAME,
      query: {
        suffix: store.state[AppLocationsNamespace].suffix,
        pageNum: '1',
      },
    });
    return;
  }

  document.title = `${to.meta.title} | ${SHORT_PROJECT_NAME}`;

  next();
});

export const createRouter = () => router;

export default router;
