export const namespace = 'rules';

export const MutationTypes = {
  SET_RULES: 'SET_RULES',
  SET_RULE: 'SET_RULE',
  SET_RULE_SLICE: 'SET_RULE_SLICE',
  SET_RULE_HISTORY: 'SET_RULE_HISTORY',
  SET_RULE_HISTORY_TOTAL_COUNT: 'SET_RULE_HISTORY_TOTAL_COUNT',
  SET_RULES_TOTAL_COUNT: 'SET_RULES_TOTAL_COUNT',
};

export const ActionTypes = {
  GET_RULES: 'GET_RULES',
  GET_RULE: 'GET_RULE',
  GET_RULE_SLICE: 'GET_RULE_SLICE',
  GET_RULE_HISTORY: 'GET_RULE_HISTORY',
};
