import { AppLocationsNamespace } from '@store/types';

import { OrderItemsApiService } from './_services/orderItems.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    orderItems: [],
    orderItem: {},
  },

  mutations: {
    [MutationTypes.SET_ORDER_ITEMS](state, payload) {
      state.orderItems = payload;
    },

    [MutationTypes.SET_ORDER_ITEM](state, payload) {
      state.orderItem = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_ORDER_ITEMS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items } = await OrderItemsApiService.getOrderItems({ suffix, ...params });

      commit(MutationTypes.SET_ORDER_ITEMS, items || []);
    },

    async [ActionTypes.GET_ORDER_ITEM](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_ORDER_ITEM,
        await OrderItemsApiService.getOrderItem({ suffix, ...params }),
      );
    },
  },
};
