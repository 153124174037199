export default [
  {
    path: '/profit/substrates',
    name: 'profitSubstrates',
    component: () => import(/* webpackChunkName: "profitSubstrates" */ './_views/DsbSubstrates.vue'),
    meta: {
      title: 'Substrates',
      group: 'profit',
      navigation: {
        name: 'Substrates',
        to: {
          name: 'profitSubstrates',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
