import { CancelToken } from 'axios';

import { ApiServiceV1 } from '@services/api';

let cancelGetDashboardStatistics = () => {};
let cancelGetDashboardChart = () => {};

export const DashboardApiService = {
  async getDashboardStatistics({ suffix }) {
    cancelGetDashboardStatistics();

    const { data } = await ApiServiceV1.get(`${suffix}/dashboard`, {
      cancelToken: new CancelToken((c) => {
        cancelGetDashboardStatistics = c;
      }),
    });

    return data || {};
  },

  async getDashboardChart({ suffix, from, to }) {
    cancelGetDashboardChart();

    const { data } = await ApiServiceV1.get(`${suffix}/dashboard/chart`, {
      params: {
        from,
        to,
      },
      cancelToken: new CancelToken((c) => {
        cancelGetDashboardChart = c;
      }),
    });

    return data || [];
  },

  async downloadReportCSV({ suffix }) {
    return ApiServiceV1.get(`${suffix}/dashboard/report`, { responseType: 'arraybuffer' });
  },
};
