export const multiLoadingSpinner = {
  data() {
    return {
      $_loadingSpinners: [],
    };
  },

  methods: {
    $_addItemToLoadingSpinners(item) {
      this.$data.$_loadingSpinners.push(item);
    },

    $_removeItemFromLoadingSpinners(item) {
      this.$data.$_loadingSpinners = this.$data.$_loadingSpinners.filter((el) => el !== item);
    },

    $_checkItemInLoadingSpinners(item) {
      return this.$data.$_loadingSpinners.includes(item);
    },
  },
};
