import { AppLocationsNamespace } from '@store/types';

import { PressSheetsApiService } from './_services/pressSheets.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    pressSheets: [],
  },

  mutations: {
    [MutationTypes.SET_PRESS_SHEETS](state, payload) {
      state.pressSheets = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_PRESS_SHEETS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items } = await PressSheetsApiService.getPressSheets({ suffix, ...params });

      commit(MutationTypes.SET_PRESS_SHEETS, items || []);
    },
  },
};
