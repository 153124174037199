import Wrapper from './_views/Wrapper.vue';

const parentRouteConfig = {
  name: 'Order Items',
  to: {
    name: 'orderItems',
    query: {
      suffix: '',
      pageNum: '1',
    },
  },
};

export default [
  {
    path: '/order-items',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'orderItems',
        component: () => import(/* webpackChunkName: "orderItems" */ './_views/DsbOrderItems'),
        meta: {
          title: 'Order Items',
          navigation: {
            name: 'Order Items',
            position: 2,
            icon: 'view_list',
            to: {
              name: 'orderItems',
              query: {
                suffix: '',
                pageNum: '1',
              },
            },
          },
        },
      },
      {
        path: ':id',
        name: 'orderItem',
        component: () => import(/* webpackChunkName: "orderItem" */ './_views/DsbOrderItem.vue'),
        meta: {
          title: 'Order Item',
          parent: parentRouteConfig,
        },
      },
    ],
  },
];
