export default [
  {
    path: '/profit/products',
    name: 'profitProducts',
    component: () => import(/* webpackChunkName: "profitProducts" */ './_views/DsbProducts.vue'),
    meta: {
      title: 'Products',
      group: 'profit',
      navigation: {
        name: 'Products',
        to: {
          name: 'profitProducts',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
