import axios from 'axios';

import { AuthLocalStorageService } from '@services/localStorage';

import { removeLastSlashFromUrl } from '@helpers';

import router from '@router';

const axiosInstanceParams = {
  headers: {
    Authorization: {
      toString() {
        return `bearer ${AuthLocalStorageService.getToken()}`;
      },
    },
  },
  validateStatus(status) {
    if (status === 401 || status === 403) {
      AuthLocalStorageService.removeToken();
      router.push({ name: status === 401 ? 'login' : 'forbidden' });
    }

    return status >= 200 && status < 300;
  },
};

export const API_URL = removeLastSlashFromUrl(process.env.VUE_APP_API.trim());

export const API_POSTFIX_V1 = '/api/v1/';

export const generateXHRErrorResponse = (e) => {
  const { data, status } = e.response || {};
  const response = { status, msg: '' };

  if (data && typeof data === 'object' && (data.message || data.Message || data.Error)) {
    response.msg = data.message || data.Message || data.Error;
  } else if (data && typeof data !== 'object') {
    response.msg = data;
  } else {
    response.msg = 'Sorry, something went wrong!';
  }

  return response;
};

export const ApiServiceV1 = axios.create({
  baseURL: `${API_URL}${API_POSTFIX_V1}`,
  ...axiosInstanceParams,
});

// Response resolvers
const resolve = (response) => response;
const reject = (e) => Promise.reject(
  axios.isCancel(e) ? { msg: e.message } : generateXHRErrorResponse(e),
);

// Configure service instance interceptors
ApiServiceV1.interceptors.response.use(resolve, reject);

export default {
  ApiServiceV1,
  API_URL,
  API_POSTFIX_V1,
  generateXHRErrorResponse,
};
