export const useDialog = ({ openHandlerName = 'onDialogOpen' } = {}) => ({
  model: {
    type: 'input',
    prop: 'value',
  },
  props: {
    value: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    submitBtnName() {
      return this.creator ? 'Create' : 'Save';
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue && this[openHandlerName]) {
          this[openHandlerName]();
        }
      },
      immediate: true,
    },
  },
  methods: {
    $_closeDialog() {
      this.dialog = false;
    },
  },
});

export const useEditorWithDialog = ({
  editableItemName = 'editableItem',
  openTriggerName = 'openEditorDialog',
  editorModeFlagName = 'isEditorCreateMode',
  editorDialogFlagName = 'editorDialog',
  editableItemDefaultValue = {},
} = {}) => ({
  data: () => ({
    [editableItemName]: editableItemDefaultValue,
    [editorDialogFlagName]: false,
    [editorModeFlagName]: true,
  }),
  methods: {
    [openTriggerName](data) {
      if (data) {
        this.$set(this, editableItemName, data);
      }
      this[editorModeFlagName] = !data;
      this[editorDialogFlagName] = true;
    },
  },
});

export const useComponentWithDialog = ({
  openTriggerName = 'openDialog',
  dialogFlagName = 'dialog',
} = {}) => ({
  data: () => ({ [dialogFlagName]: false }),
  methods: {
    [openTriggerName]() {
      this[dialogFlagName] = true;
    },
  },
});
