export const provideThemeSettings = {
  computed: {
    $_themeTextColorClass() {
      return {
        'white--text': this.$vuetify.theme.dark,
      };
    },

    $_themeTextColor() {
      return this.$vuetify.theme.dark ? '#fff' : '#000';
    },
  },
};
