export const namespace = 'locations';

export const MutationTypes = {
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_LOCATION: 'SET_LOCATION',
};

export const ActionTypes = {
  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_LOCATION: 'GET_LOCATION',
};
