import { LocalStorageService } from './localStorage.service';

const LOCATIONS_ID = 'locations';
const LOCATION_SUFFIX_ID = 'suffix';

export const AppLocationsLocalStorageService = {
  setLocations: (locations) => LocalStorageService.setItem(LOCATIONS_ID, locations),
  setLocationSuffix: (suffix) => LocalStorageService.setItem(LOCATION_SUFFIX_ID, suffix),

  getLocations: () => LocalStorageService.getItem(LOCATIONS_ID) || [],
  getLocationSuffix: () => LocalStorageService.getItem(LOCATION_SUFFIX_ID) || '',
};
