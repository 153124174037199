var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application-root-container"},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.needMainApp)?_c('vbt-app',{attrs:{"title":_vm.projectTitle,"username":_vm.username,"navigation-config":_vm.navigationConfig,"route-info-config":_vm.routeInfoConfig,"bug-report-info":_vm.bugReportInfo},on:{"click:logout":_vm.onLogout},scopedSlots:_vm._u([{key:"header-tools",fn:function(){return [_c('v-layout',{attrs:{"align-center":""}},[_c('v-spacer'),_c('dsb-order-processing-pause-widget',{staticClass:"mt-2 mr-3"}),_c('location-selector',{staticClass:"mr-4"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticStyle:{"margin-top":"2px","margin-right":"10px"},attrs:{"icon":"","small":"","color":"white","to":{
                    name: 'locations',
                    query: {
                      suffix: _vm.suffix,
                    },
                  }}},[_c('v-icon',[_vm._v(" location_on ")])],1)],1)]}}],null,false,1014811092)},[_vm._v(" Locations ")]),_c('dsb-notifications-widget')],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-layout',{attrs:{"justify-space-between":""}},[_vm._v(" "+_vm._s(_vm.copyright)+" "),_c('span',[_c('a',{staticClass:"white--text mr-1",attrs:{"href":_vm.API_URL,"target":"_blank"}},[_vm._v(" API v."+_vm._s(_vm.apiVersion)+" ")]),_vm._v(" ("+_vm._s(_vm.UI_VERSION)+") ")])])]},proxy:true}],null,false,2907902436)},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1):_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.$route.name === 'forbidden')?_c('vbt-error-page',{attrs:{"error-code":"403","error-title":"Access Denied/Forbidden","buttons":[{ name: 'Logout', callback: _vm.onLogout }]}}):_vm._e(),(_vm.$route.name === 'error404')?_c('vbt-error-page',{attrs:{"buttons":_vm.error404BtnsConfig}}):_vm._e(),_c('router-view',{attrs:{"name":"login"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }