import { EnumsApiService } from '@services/api';

import { MutationTypes, ActionTypes } from '@store/types/enums.types';

const mapEnumToStrArr = (items, key = 'value') => (items || []).map(({ [key]: value }) => value);

export default {
  namespaced: true,

  state: {
    enums: {},
  },

  mutations: {
    [MutationTypes.SET_ENUMS](state, payload) {
      state.enums = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_ENUMS]({ commit }) {
      commit(MutationTypes.SET_ENUMS, await EnumsApiService.getEnums());
    },
  },

  getters: {
    days: ({ enums: { daysOfWeek: items } }) => mapEnumToStrArr(items),
    ruleStatuses: ({ enums: { orderFilterStates: items } }) => mapEnumToStrArr(items),
    inputMessagesTypes: ({ enums: { messageTypes: items } }) => mapEnumToStrArr(items),
    inputMessagesStates: ({ enums: { messageStates: items } }) => mapEnumToStrArr(items),
    outputMessagesTypes: ({ enums: { outputMessageTypes: items } }) => mapEnumToStrArr(items),
    outputMessagesStates: ({ enums: { outputMessageState: items } }) => mapEnumToStrArr(items),
    orderItemsStates: ({ enums: { orderItemStates: items } }) => mapEnumToStrArr(items),
    uvOrientations: ({ enums: { uvOrientations: items } }) => mapEnumToStrArr(items),
    laminatings: ({ enums: { laminatings: items } }) => mapEnumToStrArr(items),
    variableUvs: ({ enums: { variableUvs: items } }) => mapEnumToStrArr(items),
    covers: ({ enums: { covers: items } }) => mapEnumToStrArr(items),
    roundedCorners: ({ enums: { roundedCorners: items } }) => mapEnumToStrArr(items),
    foilings: ({ enums: { foilings: items } }) => mapEnumToStrArr(items),
    contracts: ({ enums: { contracts: items } }) => mapEnumToStrArr(items),
    accessJournalTypes: ({ enums: { accessTypes: items } }) => mapEnumToStrArr(items),
  },
};
