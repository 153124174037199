export default [
  {
    path: '/messages/input',
    name: 'messagesInputMessages',
    component: () => import(/* webpackChunkName: "messagesInputMessages" */ './_views/DsbInputMessages.vue'),
    meta: {
      title: 'Input Messages',
      group: 'messages',
      navigation: {
        name: 'Input',
        to: {
          name: 'messagesInputMessages',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
