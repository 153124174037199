import { AppLocationsNamespace } from '@store/types';

import { MachinesApiService } from './_services/machines.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    machines: [],
    machinesTotalCount: 0,
    machineHistory: [],
  },

  mutations: {
    [MutationTypes.SET_MACHINES](state, payload) {
      state.machines = payload;
    },

    [MutationTypes.SET_MACHINES_TOTAL_COUNT](state, payload) {
      state.machinesTotalCount = payload;
    },

    [MutationTypes.SET_MACHINE_HISTORY](state, payload) {
      state.machineHistory = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_MACHINES](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items, totalCount } = await MachinesApiService.getMachines({
        suffix, ...params,
      });

      commit(MutationTypes.SET_MACHINES, items || []);
      commit(MutationTypes.SET_MACHINES_TOTAL_COUNT, totalCount);
    },

    async [ActionTypes.GET_MACHINE_HISTORY](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_MACHINE_HISTORY,
        await MachinesApiService.getMachineHistory({ suffix, ...params }),
      );
    },
  },
};
