import { mapState, mapGetters, mapActions } from 'vuex';

import { decodeJWT } from '@osvick/helpers';

import { API_URL } from '@services/api';

import {
  AppNamespace,
  AppActionTypes,
  AuthNamespace,
  AuthActionTypes,
  EnumsNamespace,
  EnumsActionTypes,
  AppLocationsNamespace,
} from '@store/types';

import { PROJECT_NAME } from '@config/application';
import generateNavigation from '@config/navigation';
import generateRouteInfo, { MAIN_ROUTE_NAME } from '@config/routeInformation';

import LocationSelector from '@components/layouts/LocationSelector.vue';

import { DsbNotificationsWidget } from '@modules/notifications';
import { DsbOrderProcessingPauseWidget } from '@modules/orderProcessing';

import { provideThemeSettings } from '@mixins';

import { version as UI_VERSION } from '../../../../package.json';

export default {
  name: 'AppWrapper',

  components: {
    LocationSelector,
    DsbNotificationsWidget,
    DsbOrderProcessingPauseWidget,
  },

  mixins: [
    provideThemeSettings,
  ],

  data() {
    return {
      API_URL,
      UI_VERSION,
      copyright: `© Opensoft - ${new Date().getFullYear()}`,
    };
  },

  computed: {
    ...mapState(AuthNamespace, ['isAuthenticated']),
    ...mapState(AppNamespace, ['apiVersion']),
    ...mapGetters(AppNamespace, ['username']),
    ...mapState(AppLocationsNamespace, ['suffix']),

    projectTitle() {
      return {
        name: PROJECT_NAME,
        to: {
          name: MAIN_ROUTE_NAME,
          query: {
            suffix: this.suffix,
            pageNum: '1',
            status: 'Active',
          },
        },
      };
    },

    needMainApp() {
      const { name } = this.$route;
      return name !== 'error404' && name !== 'forbidden' && name !== 'login' && this.isAuthenticated;
    },

    navigationConfig() {
      return generateNavigation(this.$router.options.routes, this.$route);
    },

    routeInfoConfig() {
      const { routes = [] } = this.$router.options;
      const MAIN_ROUTE = routes.reduce((acc, route) => {
        if (route.name === MAIN_ROUTE_NAME) {
          acc = route; // eslint-disable-line
          return acc;
        }
        let r;
        if (route.children) {
          r = route.children.find(({ name }) => name === MAIN_ROUTE_NAME);
        }
        if (r) {
          acc = r; // eslint-disable-line
        }
        return acc;
      }, {});
      const MAIN_ROUTE_CONFIG = {
        name: MAIN_ROUTE_NAME,
        title: MAIN_ROUTE.meta.title,
        to: MAIN_ROUTE.meta.navigation.to,
      };
      return generateRouteInfo(this.$route, MAIN_ROUTE_CONFIG);
    },

    error404BtnsConfig() {
      return [
        {
          name: 'Go back',
          callback: () => this.$router.go(-2),
        },
        {
          name: 'Go home',
          callback: () => this.$router.push({
            name: MAIN_ROUTE_NAME,
            query: {
              suffix: this.suffix,
              pageNum: '1',
              status: 'Active',
            },
          }),
        },
      ];
    },

    bugReportInfo() {
      const { name, email } = this.token ? decodeJWT(this.token) : {};

      return {
        projectName: PROJECT_NAME,
        uiVersion: this.UI_VERSION,
        apiVersion: this.apiVersion,
        url: window.location.href,
        username: name,
        email,
      };
    },
  },

  created() {
    this.initApp();
  },

  methods: {
    ...mapActions(AuthNamespace, {
      onLogout(dispatch) {
        dispatch(AuthActionTypes.LOGOUT, { routeName: this.$route.name });
        this.$router.push({ name: 'login' });
      },
    }),

    ...mapActions(AppNamespace, {
      initApp(dispatch) {
        dispatch(AppActionTypes.GET_APP_SETTINGS);
        if (this.isAuthenticated) {
          this.getAppData();
        }
      },
    }),

    ...mapActions(EnumsNamespace, { getEnums: EnumsActionTypes.GET_ENUMS }),

    getAppData() {
      this.getEnums();
    },
  },
};
