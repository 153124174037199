export const namespace = 'app'; // APP_NAMESPACE

export const MutationTypes = {
  SET_UM_URL: 'SET_UM_URL',
  SET_CLIENT_ID: 'SET_CLIENT_ID',
  SET_API_VERSION: 'SET_API_VERSION',
};

export const ActionTypes = {
  GET_APP_SETTINGS: 'GET_APP_SETTINGS',
};
