import { decodeJWT } from '@osvick/helpers';

import { AppApiService } from '@services/api';
import { AppLocalStorageService } from '@services/localStorage';

import { AuthNamespace } from '@store/types';

import { MutationTypes, ActionTypes } from '@store/types/app.types';

export default {
  namespaced: true,

  state: {
    userManagerUrl: AppLocalStorageService.getUMUrl(),
    clientId: AppLocalStorageService.getClientId(),
    apiVersion: AppLocalStorageService.getAPIVersion(),
  },

  mutations: {
    [MutationTypes.SET_UM_URL](state, payload) {
      state.userManagerUrl = payload;
      AppLocalStorageService.setUMUrl(payload);
    },

    [MutationTypes.SET_CLIENT_ID](state, payload) {
      state.clientId = payload;
      AppLocalStorageService.setClientId(payload);
    },

    [MutationTypes.SET_API_VERSION](state, payload) {
      state.apiVersion = payload;
      AppLocalStorageService.setAPIVersion(payload);
    },
  },

  actions: {
    async [ActionTypes.GET_APP_SETTINGS]({ commit }) {
      const { umUrl, version, clientId } = await AppApiService.getAppSettings();

      commit(MutationTypes.SET_UM_URL, umUrl);
      commit(MutationTypes.SET_CLIENT_ID, clientId);
      commit(MutationTypes.SET_API_VERSION, version);
    },
  },
  getters: {
    username: (state, getters, { [AuthNamespace]: { token } }) => decodeJWT(token).name,
  },
};
