import { AppLocationsNamespace } from '@store/types';

import { createLocation } from './_meta/location';

import { LocationsApiService } from './_services/locations.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    locations: [],
    location: createLocation(),
  },

  mutations: {
    [MutationTypes.SET_LOCATIONS](state, payload) {
      state.locations = payload;
    },

    [MutationTypes.SET_LOCATION](state, payload) {
      state.location = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_LOCATIONS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_LOCATIONS,
        await LocationsApiService.getLocations({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_LOCATION](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_LOCATION,
        await LocationsApiService.getLocation({ suffix, ...params }),
      );
    },
  },
};
