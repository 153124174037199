import { format, parseISO } from 'date-fns';

const defaultDateTimeFormat = 'yyyy-MM-dd, hh:mm a';
const defaultDateFormat = 'yyyy-MM-dd';

export const formatDisplayDate = (date, {
  type = 'local', defaultValue = '-', dateFormat = defaultDateTimeFormat,
} = {}) => {
  if (!date) {
    return defaultValue;
  }

  const dateParsedISO = parseISO(date);
  // dirty hack for detect UTC-0
  // used while not fixed dates in backend
  // remove when will be used one format for UTC dates
  let dateUTC = new Date(date.toLowerCase().endsWith('z') ? date : `${date}Z`);
  if (dateUTC.toString().toLowerCase() === 'invalid date') {
    dateUTC = new Date(date);
  }

  return format(type === 'local' ? dateUTC : dateParsedISO, dateFormat);
};

export const getDatesRangeByLastDays = (daysCount = 1, dateFormat = defaultDateFormat) => {
  const today = new Date();
  const lastDays = new Date(today.setDate(today.getDate() - daysCount));

  return [
    format(lastDays, dateFormat),
    format(new Date(), dateFormat),
  ];
};

export const getAllowedDatePickerRange = ({ nextDaysCount = 1, prevYearsCounts = 2 } = {}) => {
  const today = new Date();
  const nextDays = today.setDate(today.getDate() + nextDaysCount);

  return {
    min: `${(new Date()).getFullYear() - prevYearsCounts}-01-01`,
    max: (new Date(nextDays)).toISOString(),
  };
};

export const getDatesRangeObjFromDatesArray = (dates, {
  startDateName = 'startDate', endDateName = 'endDate',
} = {}) => {
  if (!dates || !dates.length || !(dates[0] || dates[0])) {
    throw new Error(`Invalid serialized dates: ${JSON.stringify(dates)}`);
  }

  return {
    [startDateName]: dates[0],
    [endDateName]: `${dates[1]} 23:59:59`,
  };
};

export const getDatesDifference = (d1, d2 = new Date()) => {
  const SECONDS_STRUCTURE = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  let delta = Math.abs(new Date(d1) - d2) / 1000;

  return Object.keys(SECONDS_STRUCTURE).reduce((acc, key) => {
    acc[key] = Math.floor(delta / SECONDS_STRUCTURE[key]);
    delta -= acc[key] * SECONDS_STRUCTURE[key];

    return acc;
  }, {
    formatString({
      year = true,
      month = true,
      week = true,
      day = true,
      hour = true,
      minute = true,
      second = true,
      withShorts = false,
    } = {}) {
      const options = {
        year, month, week, day, hour, minute, second,
      };
      const types = [
        'year', 'month', 'week', 'day', 'hour', 'minute', 'second',
      ];
      const shorts = {
        year: 'y',
        month: 'm',
        week: 'w',
        day: 'd',
        hour: 'hr',
        minute: 'min',
        second: 'sec',
      };
      const getStrByNumber = (n, str) => (n === 1 ? str : `${str}s`);

      return types.reduce((acc, type) => {
        const n = this[type];

        if (options[type] && n) {
          acc.push(`${n}${withShorts ? '' : ' '}${withShorts ? shorts[type] : getStrByNumber(n, type)}`);
        }

        return acc;
      }, []).join(' ');
    },
  });
};

export const parseDateStringToISO = (str) => (new Date(str)).toISOString();
