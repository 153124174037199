<template>
  <v-btn
    :loading="pauseTimeLoading || pauseActionLoading"
    color="warning"
    small
    text
    @click="pauseAction"
  >
    <v-icon>
      {{ isPaused ? 'play_arrow' : 'pause' }}
    </v-icon>

    <strong class="ml-1">
      {{ isPaused ? 'Resume processing:' : 'Pause processing' }}

      <span v-if="pauseTimeInMinutes">
         {{ pauseTimeInMinutes }} min
      </span>
    </strong>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';

import { createTicker, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { OrderProcessingApiService } from '../_services/orderProcessing.api.service';

const pauseTimeTicker = createTicker();

export default {
  name: 'DsbOrderProcessingPauseWidget',

  data() {
    return {
      pauseTimeInMinutes: 0,

      pauseTimeLoading: false,
      pauseActionLoading: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    isPaused() {
      return this.pauseTimeInMinutes >= 1;
    },

    pauseAction() {
      return this.isPaused ? this.stopPause : this.startPause;
    },
  },

  watch: {
    suffix: {
      handler(suffix) {
        if (suffix) {
          this.reloadTicker({ suffix, initLoading: true });
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    pauseTimeTicker.destroyTicker();
  },

  methods: {
    startPause() {
      this.$VBlackerTheme.alert.warning({
        validator: (v) => (Number.isNaN(Number(v)) ? false : Number(v) >= 1),
        label: 'Pause time in minutes (greater or equal 1)',
        text: 'Start pause processing?',
        withInput: true,
        outlined: true,
      }, ({ value }) => this.wrapToLoadingFn({
        req: OrderProcessingApiService.startPause.bind({}, {
          minutes: Number(value),
          suffix: this.suffix,
        }),
        loadingFlagName: 'pauseActionLoading',
        onSuccess: async () => {
          try {
            await this.reloadTicker({ suffix: this.suffix });
          } finally {
            this.pauseActionLoading = false;
          }
        },
        onReject: (e) => {
          this.$VBlackerTheme.notification.error(e?.msg || `${e}`);
          this.pauseActionLoading = false;
        },
        onFinally: () => {},
      }));
    },

    stopPause() {
      this.$VBlackerTheme.alert.warning({
        text: 'Resume processing?',
      }, () => this.wrapToLoadingFn({
        req: OrderProcessingApiService.stopPause.bind({}, {
          suffix: this.suffix,
        }),
        loadingFlagName: 'pauseActionLoading',
        onSuccess: async () => {
          try {
            await this.reloadTicker({ suffix: this.suffix });
          } finally {
            this.pauseActionLoading = false;
          }
        },
        onReject: (e) => {
          this.$VBlackerTheme.notification.error(e?.msg || `${e}`);
          this.pauseActionLoading = false;
        },
        onFinally: () => {},
      }));
    },

    getPauseTimeInMinutes({ suffix, initLoading }) {
      return this.wrapToLoadingFn({
        req: OrderProcessingApiService.getPauseTimeInMinutes.bind({}, { suffix }),
        loadingFlagName: initLoading ? 'pauseTimeLoading' : false,
        onSuccess: (r) => {
          this.pauseTimeInMinutes = r;
        },
      });
    },

    reloadTicker({ suffix, initLoading }) {
      pauseTimeTicker.stopTicker();
      pauseTimeTicker.loadTicker({
        req: () => this.getPauseTimeInMinutes(suffix),
      });

      return this.getPauseTimeInMinutes({ suffix, initLoading });
    },

    wrapToLoadingFn,
  },
};
</script>
