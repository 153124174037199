import { AuthLocalStorageService } from '@services/localStorage';
import { ApiServiceV1 } from './api.service';

export const AuthApiService = {
  async checkUserAuth() {
    try {
      await ApiServiceV1.get('auth/validate', {
        headers: {
          Authorization: `bearer ${AuthLocalStorageService.getToken()}`,
        },
      });

      return Promise.resolve();
    } catch (e) {
      AuthLocalStorageService.removeToken();

      return Promise.reject(e);
    }
  },
};
