import { ApiServiceV1 } from '@services/api';

// API instance called order, but entity in DB and UI is order item
export const OrderItemsApiService = {
  async getOrderItems({
    suffix, itemId = null, orderId = null, productType = null,
    tags = null, state = null, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/order`, {
      params: {
        tags: (!tags || !tags.length) ? null : tags.join(','),
        itemId,
        orderId,
        productType,
        state,
        pageNum,
        pageSize,
      },
    });

    return data || {};
  },

  async getOrderItem({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/order/${id}`);

    return data || {};
  },

  syncOrderItemsByIds({ suffix, ids }) {
    return ApiServiceV1.post(`${suffix}/order/sync-ids`, { ids });
  },

  async testOrderItem({ suffix, orderId: providerOrder }) {
    const { data } = await ApiServiceV1.post(`${suffix}/order/test/${providerOrder}`);

    return data || [];
  },

  forceBuildOrderItem({ suffix, id }) {
    return ApiServiceV1.post(`${suffix}/order/${id}/force`);
  },

  async getTags({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/order/tags`);
    return data || [];
  },
};
