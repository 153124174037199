const getFormComponent = function getFormComponent(formRefPropertyName) {
  return this.$refs[this[formRefPropertyName]];
};

export const useFormWithRef = ({
  symbolRefDesc = 'editor',
  formRefPropertyName = 'formRef',
  formValidPropName = 'isValidForm',
} = {}) => ({
  data: () => ({
    [formValidPropName]: true,
    [formRefPropertyName]: Symbol(symbolRefDesc),
  }),

  methods: {
    $_validateForm() {
      const form = getFormComponent.call(this, formRefPropertyName);

      return !form || form.validate();
    },

    $_resetFormValidation() {
      const form = getFormComponent.call(this, formRefPropertyName);

      if (form) {
        form.resetValidation();
      }
    },

    $_resetForm() {
      const form = getFormComponent.call(this, formRefPropertyName);

      if (form) {
        form.reset();
      }
    },

    $_resetFormAndValidation() {
      this.$_resetForm();
      this.$_resetFormValidation();
    },
  },
});
