export default {
  path: '/dashboard',
  name: 'dashboard',
  component: () => import(/* webpackChunkName: "dashboard" */ './_views/DsbDashboard.vue'),
  meta: {
    title: 'Dashboard',
    navigation: {
      position: 0,
      name: 'Dashboard',
      icon: 'pie_chart',
      specialQueries: ['suffix'],
      to: {
        name: 'dashboard',
        query: {
          suffix: '',
        },
      },
    },
  },
};
