export const namespace = 'profitPressSheetsTemplates';

export const MutationTypes = {
  SET_PRESS_SHEETS_TEMPLATES: 'SET_PRESS_SHEETS_TEMPLATES',
  SET_PRESS_SHEETS_TEMPLATE: 'SET_PRESS_SHEETS_TEMPLATE',
  SET_PRESS_SHEETS_TEMPLATES_TOTAL_COUNT: 'SET_PRESS_SHEETS_TEMPLATES_TOTAL_COUNT',
};

export const ActionTypes = {
  GET_PRESS_SHEETS_TEMPLATES: 'GET_PRESS_SHEETS_TEMPLATES',
  GET_PRESS_SHEETS_TEMPLATE: 'GET_PRESS_SHEETS_TEMPLATE',
};
