import { ApiServiceV1 } from '@services/api';

// API instance called basket, but entity in DB and UI is press sheets
export const PressSheetsApiService = {
  async getPressSheets({
    suffix, orderId = null, orderItemId = null, pressSheetId = null,
    basketId = null, ruleId = null, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/basket`, {
      params: {
        suffix, orderId, orderItemId, pressSheetId, basketId, ruleId, pageNum, pageSize,
      },
    });

    return data || {};
  },
};
