<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn
          icon
          small
          :loading="loading"
          :disabled="disabled"
          :color="toggleColor"
          @click="switchState"
        >
          <v-icon>
            {{ toggleIcon }}
          </v-icon>
        </v-btn>
      </span>
    </template>
    {{ tooltipText }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'DsbStateSwitch',

  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    state: Boolean,
    loading: Boolean,
    disabled: Boolean,
  },

  computed: {
    tooltipText() {
      const { state: s } = this;

      return `${s ? 'Enabled' : 'Disabled'}, click to ${s ? 'disable' : 'enable'}`;
    },
    toggleColor() {
      return this.state ? 'success' : 'error';
    },
    toggleIcon() {
      return this.state ? 'toggle_on' : 'toggle_off';
    },
  },

  methods: {
    switchState() {
      this.$emit('state:change', {
        prevState: this.state,
        nextState: !this.state,
        id: this.id,
      });
    },
  },
};
</script>
