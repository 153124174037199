import { ApiServiceV1 } from '@services/api';

export const SubstratesApiService = {
  async getSubstrates({
    suffix, name = null,
    pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/substrate`, {
      params: {
        name, pageNum, pageSize,
      },
    });

    return data || {};
  },

  syncSubstrates({ suffix }) {
    return ApiServiceV1.post(`${suffix}/substrate/sync`);
  },
};
