import { genGUID } from '@helpers';

import { ApiServiceV1 } from '@services/api';

export const RulesApiService = {
  async getRules({
    suffix, id = null, status = null, tags = null,
    productType = null, template = null, substrate = null,
    pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule`, {
      params: {
        tags: (!tags || !tags.length) ? null : tags.join(','),
        id,
        status,
        productType,
        substrate,
        template,
        pageNum,
        pageSize,
      },
    });

    return data || {};
  },

  async getRule({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/${id}`);

    (data.pressSheetTemplates || []).forEach((pst) => {
      // special key for optimized rerender
      pst._key = genGUID(); // eslint-disable-line
    });

    return data || {};
  },

  async getRuleStatistic({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/${id}/statistic`);

    return data || {};
  },

  async checkRule({ suffix, rule }) {
    const { data } = await ApiServiceV1.post(`${suffix}/rule/check`, rule);

    return data;
  },

  async createRule({ suffix, data }) {
    const { data: res } = await ApiServiceV1.post(`${suffix}/rule`, data);

    return res || {};
  },

  updateRule({ suffix, id, data }) {
    return ApiServiceV1.post(`${suffix}/rule/${id}`, data);
  },

  async testRule({ suffix, id, testSearchTerm }) {
    const { data } = await ApiServiceV1.post(`${suffix}/rule/${id}/test`, { testSearchTerm });

    return data;
  },

  async getRuleHistory({
    suffix, id, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/${id}/history`, {
      params: {
        pageNum, pageSize,
      },
    });

    return data || {};
  },

  async getRuleHistoryCompare({ suffix, ruleId, historyId }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/${ruleId}/history/${historyId}`);

    return data || [];
  },

  async getRuleSchedule({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/${id}/schedule`);

    return data || {};
  },

  async getRuleSlice({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/slice/${id}`);

    return data || {};
  },

  updateRuleSchedule({ suffix, id, data }) {
    return ApiServiceV1.post(`${suffix}/rule/${id}/schedule`, data);
  },

  syncRuleWithProFIT({ suffix, id, data }) {
    return ApiServiceV1.post(`${suffix}/rule/${id}/sync`, data);
  },

  removeRule({ suffix, id }) {
    return ApiServiceV1.delete(`${suffix}/rule/${id}`);
  },

  switchRuleState({ suffix, id, state: disabled }) {
    return ApiServiceV1.post(`${suffix}/rule/${id}/state`, { disabled });
  },

  async getTags({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/rule/tags`);
    return data || [];
  },
};
