import { AppLocationsNamespace } from '@store/types';

import { InputMessagesApiService } from './_services/inputMessages.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    inputMessages: [],
  },

  mutations: {
    [MutationTypes.SET_INPUT_MESSAGES](state, payload) {
      state.inputMessages = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_INPUT_MESSAGES](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items } = await InputMessagesApiService.getInputMessages({ suffix, ...params });

      commit(MutationTypes.SET_INPUT_MESSAGES, items || []);
    },
  },
};
