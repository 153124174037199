export default [
  {
    path: '/press-sheets',
    name: 'pressSheets',
    component: () => import(/* webpackChunkName: "pressSheets" */ './_views/DsbPressSheets.vue'),
    meta: {
      title: 'Press Sheets',
      navigation: {
        position: 2,
        icon: 'dashboard',
        name: 'Press Sheets',
        to: {
          name: 'pressSheets',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
