import { ApiServiceV1 } from '@services/api';

export const ProductsApiService = {
  async getProducts({
    suffix, name = null, multipage = null,
    pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/product`, {
      params: {
        name, multipage, pageNum, pageSize,
      },
    });

    return data || {};
  },

  async getProductsHistory({ suffix, name }) {
    const { data } = await ApiServiceV1.get(`${suffix}/product/${encodeURIComponent(name)}/history`);

    return data || [];
  },

  syncProducts({ suffix }) {
    return ApiServiceV1.post(`${suffix}/product/sync`);
  },

  changeProductMultipageState({ suffix, state: multipage, name }) {
    return ApiServiceV1.post(
      `${suffix}/product/${encodeURIComponent(name)}/multipage`,
      { multipage },
    );
  },
};
