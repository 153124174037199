import { AppLocationsNamespace } from '@store/types';

import { ProductsApiService } from './_services/products.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    products: [],
    productsTotalCount: 0,
    productHistory: [],
  },

  mutations: {
    [MutationTypes.SET_PRODUCTS](state, payload) {
      state.products = payload;
    },

    [MutationTypes.SET_PRODUCTS_TOTAL_COUNT](state, payload) {
      state.productsTotalCount = payload;
    },

    [MutationTypes.SET_PRODUCT_HISTORY](state, payload) {
      state.productHistory = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_PRODUCTS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items, totalCount } = await ProductsApiService.getProducts({
        suffix, ...params,
      });

      commit(MutationTypes.SET_PRODUCTS, items || []);
      commit(MutationTypes.SET_PRODUCTS_TOTAL_COUNT, totalCount);
    },

    async [ActionTypes.GET_PRODUCT_HISTORY](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_PRODUCT_HISTORY,
        await ProductsApiService.getProductsHistory({ suffix, ...params }),
      );
    },
  },
};
