export default [
  {
    path: '/profit/contracts',
    name: 'profitContracts',
    component: () => import(/* webpackChunkName: "profitContracts" */ './_views/DsbContracts.vue'),
    meta: {
      title: 'Contracts',
      group: 'profit',
      navigation: {
        name: 'Contracts',
        to: {
          name: 'profitContracts',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
