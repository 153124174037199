import { ApiServiceV1 } from '@services/api';

export const InputMessagesApiService = {
  async getInputMessages({
    suffix, providerOrder = null, orderItemId = null,
    state = null, type = null, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/message`, {
      params: {
        providerOrder, orderItemId, state, type, pageNum, pageSize,
      },
    });

    return data;
  },
};
