export const namespace = 'dataLists';

export const MutationTypes = {
  SET_PRODUCTS_LIST: 'SET_PRODUCTS_LIST',
  SET_SUBSTRATES_LIST: 'SET_SUBSTRATES_LIST',
  SET_PRESS_SHEETS_TEMPLATES_LIST: 'SET_PRESS_SHEETS_TEMPLATES_LIST',
};

export const ActionTypes = {
  GET_PRODUCTS_LIST: 'GET_PRODUCTS_LIST',
  GET_SUBSTRATES_LIST: 'GET_SUBSTRATES_LIST',
  GET_PRESS_SHEETS_TEMPLATES_LIST: 'GET_PRESS_SHEETS_TEMPLATES_LIST',
};
