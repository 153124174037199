export const namespace = 'orderItems';

export const MutationTypes = {
  SET_ORDER_ITEMS: 'SET_ORDER_ITEMS',
  SET_ORDER_ITEM: 'SET_ORDER_ITEM',
};

export const ActionTypes = {
  GET_ORDER_ITEMS: 'GET_ORDER_ITEMS',
  GET_ORDER_ITEM: 'GET_ORDER_ITEM',
};
