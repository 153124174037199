export const namespace = 'autocomplete';

export const MutationTypes = {
  SET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE: 'SET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE',
  SET_PRODUCT_AUTOCOMPLETE: 'SET_PRODUCT_AUTOCOMPLETE',
  SET_SUBSTRATE_AUTOCOMPLETE: 'SET_SUBSTRATE_AUTOCOMPLETE',
  SET_MACHINE_NAME_AUTOCOMPLETE: 'SET_MACHINE_NAME_AUTOCOMPLETE',
  SET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE: 'SET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE',
};

export const ActionTypes = {
  GET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE: 'GET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE',
  GET_PRODUCT_AUTOCOMPLETE: 'GET_PRODUCT_AUTOCOMPLETE',
  GET_SUBSTRATE_AUTOCOMPLETE: 'GET_SUBSTRATE_AUTOCOMPLETE',
  GET_MACHINE_NAME_AUTOCOMPLETE: 'GET_MACHINE_NAME_AUTOCOMPLETE',
  GET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE: 'GET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE',
};
