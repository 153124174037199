import { AppLocationsNamespace } from '@store/types';

import { OutputMessagesApiService } from './_services/outputMessages.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    outputMessages: [],
    outputMessage: {},
  },

  mutations: {
    [MutationTypes.SET_OUTPUT_MESSAGES](state, payload) {
      state.outputMessages = payload;
    },

    [MutationTypes.SET_OUTPUT_MESSAGE](state, payload) {
      state.outputMessage = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_OUTPUT_MESSAGES](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items } = await OutputMessagesApiService.getOutputMessages({ suffix, ...params });

      commit(MutationTypes.SET_OUTPUT_MESSAGES, items || []);
    },

    async [ActionTypes.GET_OUTPUT_MESSAGE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_OUTPUT_MESSAGE,
        await OutputMessagesApiService.getOutputMessage({ suffix, ...params }),
      );
    },
  },
};
