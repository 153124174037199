import { CancelToken } from 'axios';

import { ApiServiceV1 } from '@services/api';

let cancelGetNotifications = () => {};

export const NotificationsApiService = {
  async getNotifications({ suffix }) {
    cancelGetNotifications();

    const { data } = await ApiServiceV1.get(`${suffix}/notification`, {
      cancelToken: new CancelToken((c) => {
        cancelGetNotifications = c;
      }),
    });

    return data || [];
  },

  removeNotification({ suffix, id }) {
    return ApiServiceV1.delete(`${suffix}/notification/${id}`);
  },

  syncNotifications({ suffix }) {
    return ApiServiceV1.post(`${suffix}/notification/sync`);
  },
};
