<template>
  <div>
    {{ formattedText }}

    <template v-if="showActions">
      <a
        v-if="showAll"
        class="info--text"
        @click="showAll = false"
      >
        hide
      </a>
      <a
        v-else
        class="info--text"
        @click="showAll = true"
      >
        ...
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DsbShortTextView',

  props: {
    text: {
      type: String,
      required: true,
    },
    shortLength: {
      type: Number,
      default: 30,
    },
  },

  data() {
    return {
      showAll: false,
    };
  },

  computed: {
    formattedText() {
      return this.showAll ? this.text : this.text.slice(0, this.shortLength);
    },
    showActions() {
      return this.text.length > this.shortLength;
    },
  },

  watch: {
    text: {
      handler(newValue) {
        this.showAll = newValue.length <= this.shortLength;
      },
      immediate: true,
    },
  },
};
</script>
