export const VALIDATION_RULES = Object.freeze({
  REQUIRED: 'required',
  REQUIRED_ARRAY: 'requiredArray',
  EMAIL: 'email',
  MIN: 'min',
  MAX: 'max',
  NUMBER: 'number',
  MAX_LENGTH: 'maxLength',
  DECIMAL_PLACES_COUNT: 'decimalPlacesCount',
  COMBOBOX: 'combobox',
  POSITIVE_NUMBER: 'positiveNumber',
  ALREADY_TAKEN: 'alreadyTaken',
});

export const VALIDATION_RULES_ACTIONS = {
  [VALIDATION_RULES.REQUIRED]: (v) => Boolean(String(v ?? '').length) || 'Required field',
  [VALIDATION_RULES.REQUIRED_ARRAY]: (v) => Boolean(v.length) || 'Required field',
  [VALIDATION_RULES.EMAIL]: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid email',
  [VALIDATION_RULES.MIN]: (min) => (v) => v >= min || `Value must be greater than or equal to ${min}`,
  [VALIDATION_RULES.MAX]: (max) => (v) => v <= max || `Value must be less than or equal to ${max}`,
  [VALIDATION_RULES.NUMBER]: (v) => typeof v === 'number' || 'Must be number',
  [VALIDATION_RULES.MAX_LENGTH]: (max) => (v) => (v && v.length <= max) || `Field cannot contain more than ${max} characters`,
  [VALIDATION_RULES.DECIMAL_PLACES_COUNT]: (v) => /^[0-9]*[.]?\d{1,2}$/.test(v) || 'Must be no more than two decimal places',
  [VALIDATION_RULES.COMBOBOX]: (v) => typeof v !== 'string' || 'Select value from list',
  [VALIDATION_RULES.POSITIVE_NUMBER]: (v) => v >= 0 || 'Value mustn\'t be negative',
  [VALIDATION_RULES.ALREADY_TAKEN]: (values) => (v) => !values.includes(v) || 'Value must be unique',
};

const getRuleNameFromConfig = (config) => (typeof config === 'string' ? config : config.rule);

const generateRule = (rule) => {
  if (typeof rule === 'string' && VALIDATION_RULES_ACTIONS[rule]) {
    return VALIDATION_RULES_ACTIONS[rule];
  }

  if (typeof rule === 'object' && VALIDATION_RULES_ACTIONS[rule.rule]) {
    return VALIDATION_RULES_ACTIONS[rule.rule](rule.value);
  }

  return false;
};

const wrapRuleToOptionalChecker = (optional, rule) => (v) => {
  if (!optional || Boolean(String(v ?? '').length)) {
    return rule(v);
  }
  return true;
};

const generateValidatorsByConfig = (config) => Object.keys(config)
  .reduce((acc, field) => {
    acc[field] = config[field]
      .reduce((accRules, ruleConfig) => {
        const ruleName = getRuleNameFromConfig(ruleConfig);
        const rule = generateRule(ruleConfig);
        if (rule) {
          accRules[ruleName] = wrapRuleToOptionalChecker(config[field].includes('optional'), rule);
        }
        return accRules;
      }, {});

    return acc;
  }, {});

export const Validator = {
  createValidator: (config) => {
    let rules = generateValidatorsByConfig(config);

    return {
      getRulesByField: (fieldName) => (rules[fieldName] ? Object.values(rules[fieldName]) : []),
      addFieldRule: (fieldName, rule) => {
        if (rules[fieldName]) {
          rules[fieldName].push(rule);
        } else {
          rules[fieldName] = [rule];
        }
      },
      updateFieldRule: (fieldName, ruleConfig, optional = false) => {
        if (!rules[fieldName]) {
          return new Error(`No available field ${fieldName}`);
        }

        const ruleName = getRuleNameFromConfig(ruleConfig);

        if (!rules[fieldName][ruleName]) {
          return new Error(`No available rule ${ruleName}`);
        }

        const rule = generateRule(ruleConfig);

        rules[fieldName][ruleName] = wrapRuleToOptionalChecker(optional, rule);

        return true;
      },
      updateFieldRules: (fieldName, c) => {
        if (!rules[fieldName]) {
          return new Error(`No available field ${fieldName}`);
        }
        rules[fieldName] = c.reduce((acc, ruleConfig) => {
          const ruleName = getRuleNameFromConfig(ruleConfig);
          const rule = generateRule(ruleConfig);
          if (rule) {
            acc[ruleName] = wrapRuleToOptionalChecker(c.includes('optional'), rule);
          }
          return acc;
        }, {});
        return true;
      },
      removeFieldRule: (fieldName, ruleName) => {
        if (!rules[fieldName]) {
          return new Error(`No available field ${fieldName}`);
        }
        if (!rules[fieldName][ruleName]) {
          return new Error(`No available rule ${ruleName}`);
        }

        delete rules[fieldName][ruleName];

        return true;
      },
      rebuildValidator: (c) => {
        rules = generateValidatorsByConfig(c);
      },
    };
  },
  useValidator: (validator) => ({
    computed: {
      validator: () => validator,
    },
  }),
  generateRule: ({ rule, config, optional = false }) => {
    const r = rule || generateRule(config);
    if (!r) {
      return new Error('Cannot create rule');
    }
    return wrapRuleToOptionalChecker(optional, r);
  },
};
