export function usePagination({ contentName = 'paginationItems' } = {}) {
  return {
    data() {
      return {
        pageNum: this.$route.query.pageNum || '1',
      };
    },
    computed: {
      goBack() {
        return {
          text: 'Back to previous',
          callback: () => this.$router.go(-1),
        };
      },
      pagedItemsCount() {
        return this[contentName].length;
      },
    },
    watch: {
      pageNum(newValue) {
        if (Number(newValue) !== Number(this.$route.query.pageNum)) {
          this.$router.push({
            query: {
              ...this.$route.query,
              pageNum: newValue,
            },
          });
        }
      },
      // eslint-disable-next-line
      '$route.query.pageNum'(newValue) {
        if (Number(newValue) !== Number(this.pageNum)) {
          this.$_setPage(newValue);
        }
      },
    },
    methods: {
      $_setPage(pageNum) {
        this.pageNum = pageNum;
      },
    },
  };
}
