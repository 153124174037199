export const namespace = 'profitMachines';

export const MutationTypes = {
  SET_MACHINES: 'SET_MACHINES',
  SET_MACHINES_TOTAL_COUNT: 'SET_MACHINES_TOTAL_COUNT',
  SET_MACHINE_HISTORY: 'SET_MACHINE_HISTORY',
};

export const ActionTypes = {
  GET_MACHINES: 'GET_MACHINES',
  GET_MACHINE_HISTORY: 'GET_MACHINE_HISTORY',
};
