<template>
  <v-chip
    small
    :color="wrappedColor"
    v-bind="$attrs"
  >
    <slot>
      {{ entity }}
    </slot>
  </v-chip>
</template>

<script>
const DEFAULT_COLOR = 'default';
// If you need new entities and colors, edit this constant
const COLORS = Object.freeze([
  {
    color: 'info',
    entities: ['New', 'Created', 'PresssheetCreated'],
  },
  {
    color: 'success',
    entities: [
      'Added', 'Success', 'Active',
      'PresssheetDistributed', 'Enabled',
    ],
  },
  {
    color: 'warning',
    entities: [
      'DisabledByTimeout', 'PresssheetNotDistributed', 'NoActivePresses',
      'NoActiveTemplates',
    ],
  },
  {
    color: 'cyan lighten-2',
    entities: ['Hold'],
  },
  {
    color: 'error',
    entities: [
      'Error', 'DisabledByCount', 'DisabledByTimeout',
      'Canceled', 'Disabled',
    ],
  },
]);

const mapColors = (entity, colors = COLORS) => {
  const { color } = colors.find(({ entities }) => entities.includes(entity)) || {};

  return color;
};

export default {
  name: 'DsbEntityBadge',

  props: {
    entity: {
      type: [String, Number],
      default: '',
    },
    // Custom colors schema
    colorsSchema: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: '',
    },
    preventDefaultColors: Boolean,
  },

  computed: {
    wrappedColor() {
      let { color } = this;

      if (this.colorsSchema.length && !color) {
        color = mapColors(this.entity, this.colorsSchema);
      }

      if (!this.preventDefaultColors && !color) {
        color = mapColors(this.entity);
      }

      return color || DEFAULT_COLOR;
    },
  },
};
</script>
