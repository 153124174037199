import { ApiServiceV1 } from '@services/api';

export const MachinesApiService = {
  async getMachines({
    suffix, key = null, name = null, state = null,
    pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/machine`, {
      params: {
        key, name, state, pageNum, pageSize,
      },
    });

    return data || {};
  },

  async getMachineHistory({ suffix, name }) {
    const { data } = await ApiServiceV1.get(`${suffix}/machine/${encodeURIComponent(name)}/history`);

    return data || [];
  },

  syncMachines({ suffix }) {
    return ApiServiceV1.post(`${suffix}/machine/sync`);
  },

  changeMachineState({ suffix, state, name }) {
    return ApiServiceV1.post(
      `${suffix}/machine/${encodeURIComponent(name)}/state`,
      { state },
    );
  },
};
