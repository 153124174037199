import { DataListsApiService } from '@services/api';

import { AppLocationsNamespace } from '@store/types';

import { MutationTypes, ActionTypes } from '@store/types/dataLists.types';

export default {
  namespaced: true,

  state: {
    productsList: [],
    substratesList: [],
    pressSheetsTemplatesList: [],
  },

  mutations: {
    [MutationTypes.SET_PRODUCTS_LIST](state, payload) {
      state.productsList = payload;
    },

    [MutationTypes.SET_SUBSTRATES_LIST](state, payload) {
      state.substratesList = payload;
    },

    [MutationTypes.SET_PRESS_SHEETS_TEMPLATES_LIST](state, payload) {
      state.pressSheetsTemplatesList = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_PRODUCTS_LIST](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_PRODUCTS_LIST,
        await DataListsApiService.getProductsList({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_SUBSTRATES_LIST](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_SUBSTRATES_LIST,
        await DataListsApiService.getSubstratesList({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_PRESS_SHEETS_TEMPLATES_LIST](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_PRESS_SHEETS_TEMPLATES_LIST,
        await DataListsApiService.getPressSheetsTemplatesList({ suffix, ...params }),
      );
    },
  },
};
