import { AutocompleteApiService } from '@services/api';

import { AppLocationsNamespace } from '@store/types';

import { MutationTypes, ActionTypes } from '@store/types/autocomplete.types';

export default {
  namespaced: true,

  state: {
    pressSheetsTemplatesAutocomplete: [],
    productAutocomplete: [],
    substrateAutocomplete: [],
    machineNameAutocomplete: [],
    machinePublicKeyAutocomplete: [],
  },

  mutations: {
    [MutationTypes.SET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE](state, payload) {
      state.pressSheetsTemplatesAutocomplete = payload;
    },

    [MutationTypes.SET_PRODUCT_AUTOCOMPLETE](state, payload) {
      state.productAutocomplete = payload;
    },

    [MutationTypes.SET_SUBSTRATE_AUTOCOMPLETE](state, payload) {
      state.substrateAutocomplete = payload;
    },

    [MutationTypes.SET_MACHINE_NAME_AUTOCOMPLETE](state, payload) {
      state.machineNameAutocomplete = payload;
    },

    [MutationTypes.SET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE](state, payload) {
      state.machinePublicKeyAutocomplete = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE,
        await AutocompleteApiService.getPressSheetsTemplatesAutocomplete({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_PRODUCT_AUTOCOMPLETE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_PRODUCT_AUTOCOMPLETE,
        await AutocompleteApiService.getProductAutocomplete({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_SUBSTRATE_AUTOCOMPLETE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_SUBSTRATE_AUTOCOMPLETE,
        await AutocompleteApiService.getSubstrateAutocomplete({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_MACHINE_NAME_AUTOCOMPLETE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_MACHINE_NAME_AUTOCOMPLETE,
        await AutocompleteApiService.getMachineNameAutocomplete({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_MACHINE_PUBLIC_KEY_AUTOCOMPLETE,
        await AutocompleteApiService.getMachinePublicKeyAutocomplete({ suffix, ...params }),
      );
    },
  },
};
