export const namespace = 'messagesOutputMessages';

export const MutationTypes = {
  SET_OUTPUT_MESSAGES: 'SET_OUTPUT_MESSAGES',
  SET_OUTPUT_MESSAGE: 'SET_OUTPUT_MESSAGE',
};

export const ActionTypes = {
  GET_OUTPUT_MESSAGES: 'GET_OUTPUT_MESSAGES',
  GET_OUTPUT_MESSAGE: 'GET_OUTPUT_MESSAGE',
};
