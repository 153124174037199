import { AppLocationsNamespace } from '@store/types';

import { NotificationsApiService } from './_services/notifications.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    notifications: [],
  },

  mutations: {
    [MutationTypes.SET_NOTIFICATIONS](state, payload) {
      state.notifications = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_NOTIFICATIONS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params } = {},
    ) {
      commit(
        MutationTypes.SET_NOTIFICATIONS,
        await NotificationsApiService.getNotifications({ suffix, ...params }),
      );
    },
  },
};
