import { AppLocationsNamespace } from '@store/types';

import { ContractsApiService } from './_services/contracts.api.service';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    contracts: [],
    contractHistory: [],
  },

  mutations: {
    [MutationTypes.SET_CONTRACTS](state, payload) {
      state.contracts = payload;
    },

    [MutationTypes.SET_CONTRACT_HISTORY](state, payload) {
      state.contractHistory = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_CONTRACTS](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix } = {},
    ) {
      commit(
        MutationTypes.SET_CONTRACTS,
        await ContractsApiService.getContracts({ suffix }),
      );
    },

    async [ActionTypes.GET_CONTRACT_HISTORY](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_CONTRACT_HISTORY,
        await ContractsApiService.getContractHistory({ suffix, ...params }),
      );
    },
  },
};
