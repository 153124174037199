export const wrapToLoadingFn = async function wrapToLoadingFn({
  req,
  loadingFlagName,
  initialLoadingSpinner,
  fullscreenLoadingSpinner,
  onSuccess,
  onReject,
  onFinally,
}) {
  if (loadingFlagName) {
    this[loadingFlagName] = true;
  } else if (initialLoadingSpinner) {
    this.$VBlackerTheme.openLoadingSpinner('initial');
  } else if (fullscreenLoadingSpinner) {
    this.$VBlackerTheme.openLoadingSpinner('fullscreen');
  }

  try {
    const response = await req();

    if (onSuccess) {
      await onSuccess(response);
    }

    return Promise.resolve(response);
  } catch (e) {
    if (onReject) {
      onReject(e);
    } else {
      this.$VBlackerTheme.notification.error(e?.msg || `${e}`);
    }

    return Promise.reject(e);
  } finally {
    if (onFinally) {
      onFinally();
    } else if (loadingFlagName) {
      this[loadingFlagName] = false;
    } else if (fullscreenLoadingSpinner) {
      this.$VBlackerTheme.closeLoadingSpinner();
    }
  }
};
