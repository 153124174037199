<template>
  <div class="dsb-label">
    <div>
      <strong>
        <slot />
      </strong>
    </div>
    <div
      v-if="error"
      class="error--text"
      style="font-size: 11px;"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DsbLabel',

  props: {
    error: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="css" scoped>
.dsb-label {
  display: flex;
  flex-direction: column;
}

.dsb-label .error--text {
  text-align: right;
}
</style>
