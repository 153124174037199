import { parsePropByType } from '@helpers';

export function useDataLoading({
  getterName,
  dependenciesChangeHandler,
  paged = true,
  dataParams = [],
  ignoredParams = [],
  ignoredQueryItems = [],
  specialQueryParams = [],
  contentName = '',
  queryable = true,
  searchable = true,
  parameterize = false,
}) {
  return {
    data: () => ({
      loading: false,
      loadingError: { show: false, msg: '' },
      allowGettingData: true,
    }),
    computed: {
      noContent() {
        return {
          show: contentName ? this[contentName].length < 1 : false,
          msg: 'No content',
        };
      },
    },
    watch: {
      allowGettingData: {
        handler(newValue) {
          if (!newValue) {
            return;
          }
          this.$nextTick(async () => {
            try {
              await this.$_getData();
            } finally {
              this.allowGettingData = false;
            }
          });
        },
        immediate: true,
      },
      '$route.params': {
        handler(newValue, oldValue) {
          if (
            parameterize
            && this.$_checkAllowRefreshByValues(newValue, oldValue, ignoredParams)
          ) {
            this.$_fetchData();
            this.$_onWatchHandler({ type: '$route.params', oldValue, newValue });
          }
        },
        deep: true,
      },
      '$route.query': {
        handler(newValue, oldValue) {
          if (
            queryable
            && this.$_checkAllowRefreshByValues(newValue, oldValue, ignoredQueryItems)
          ) {
            this.$_fetchData();
            this.$_onWatchHandler({ type: '$route.query', oldValue, newValue });
          }
        },
        deep: true,
      },
    },
    methods: {
      async $_getData() {
        const response = { ...this.$route.query };
        const paramsResponse = { ...this.$route.params };

        ignoredQueryItems.forEach((ignored) => delete response[ignored]);
        ignoredParams.forEach((ignored) => delete paramsResponse[ignored]);

        if (searchable) {
          Object.assign(response, {
            ...response,
            ...this.parseSearchData(this.searchData),
          });
        }

        if (parameterize) {
          Object.assign(response, {
            ...response,
            ...paramsResponse,
          });
        }

        if (paged) {
          response.pageNum = response.pageNum || 1;
        }

        dataParams.forEach((param) => {
          response[param] = this[param];
        });

        specialQueryParams.forEach(({ type, name, parser }) => {
          if (Object.prototype.hasOwnProperty.call(response, name)) {
            response[name] = parsePropByType({
              prop: response[name],
              parser,
              type,
            });
          }
        });

        this.$_showLoading();
        this.$_setLoadingError({ show: false });

        try {
          await this[getterName](response);
          return Promise.resolve();
        } catch (e) {
          this.$_setLoadingError(e);
          return Promise.reject();
        } finally {
          this.$_hideLoading();
        }
      },
      $_checkAllowRefreshByValues(newValue, oldValue, ignored) {
        if (!ignored || !ignored.length) {
          return true;
        }

        const keys = [...new Set([
          ...Object.keys(newValue),
          ...Object.keys(oldValue),
        ])];

        return keys
          .some((k) => {
            if (ignored.includes(k)) {
              return false;
            }
            return newValue[k] !== oldValue[k];
          });
      },
      $_fetchData() {
        this.allowGettingData = true;
      },
      $_refreshData() {
        if (this.$_resetSearch) {
          this.$_resetSearch();
        } else if (this.$_setPage && Number(this.pageNum) !== 1) {
          this.$_setPage('1');
        }
        this.$_fetchData();
      },
      $_showLoading() {
        this.loading = true;
      },
      $_hideLoading() {
        this.loading = false;
      },
      $_setLoadingError({ show = true, msg = '' }) {
        Object.assign(this, { loadingError: { show, msg } });
      },
      $_onWatchHandler(response) {
        if (!dependenciesChangeHandler) {
          return;
        }

        if (typeof dependenciesChangeHandler === 'string') {
          this[dependenciesChangeHandler](response);
        }

        if (typeof dependenciesChangeHandler === 'function') {
          dependenciesChangeHandler(response);
        }
      },
    },
  };
}
