export const namespace = 'appLocations';

export const MutationTypes = {
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_SUFFIX: 'SET_SUFFIX',
};

export const ActionTypes = {
  GET_LOCATIONS: 'GET_LOCATIONS',
};
