export const useStateFlag = (flag = 'isLoading', setter = 'setLoading') => ({
  data: () => ({ [flag]: false }),
  methods: setter
    ? {
      [setter](state = !this[flag]) {
        this[flag] = state;
      },
    }
    : {},
});
