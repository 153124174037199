import { ApiServiceV1 } from './api.service';

export const DataListsApiService = {
  async getProductsList({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/product/list`);

    return data || [];
  },

  async getSubstratesList({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/substrate/list`);

    return data || [];
  },

  async getPressSheetsTemplatesList({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/template/list`);

    return data || [];
  },

  async getTemplatesMachineCategoriesByTemplateId({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/template/${id}/machine-category`);

    return data || [];
  },
};
