import Vue from 'vue';
import Vuex from 'vuex';

import {
  AppNamespace,
  AuthNamespace,
  AutocompleteNamespace,
  DataListsNamespace,
  EnumsNamespace,
  AppLocationsNamespace,
} from '@store/types';

import app from './modules/app';
import auth from './modules/auth';
import autocomplete from './modules/autocomplete';
import dataLists from './modules/dataLists';
import enums from './modules/enums';
import appLocations from './modules/appLocations';

Vue.use(Vuex);

const modules = {
  [AppNamespace]: app,
  [AuthNamespace]: auth,
  [AutocompleteNamespace]: autocomplete,
  [DataListsNamespace]: dataLists,
  [EnumsNamespace]: enums,
  [AppLocationsNamespace]: appLocations,
};

// Automatic registration of store modules from @modules
const requireModules = require.context(
  '@modules/',
  true,
  /^(((\.{1,2})|([a-zA-Z]+))\/)*([a-zA-Z]+\.)?(\b(store)\b)?$/,
);
requireModules.keys().forEach((path) => {
  const moduleName = requireModules(path).namespace || path.split('/')[1];
  const module = requireModules(path).default;

  if (!module) {
    return;
  }

  if (!Object.hasOwnProperty.call(module, 'namespaced')) {
    console.warn(`Vuex module auto register (src/store/index.js) [WARN]: module "${moduleName}" is missing field "namespaced"`); // eslint-disable-line
  }

  modules[moduleName] = module;
});

export default new Vuex.Store({ modules });
