import { CancelToken } from 'axios';

import { ApiServiceV1 } from '@services/api';

let cancelGetPauseTimeInMinutes = () => {};

export const OrderProcessingApiService = {
  async getPauseTimeInMinutes({ suffix }) {
    cancelGetPauseTimeInMinutes();

    const { data } = await ApiServiceV1.get(`${suffix}/order/process/pause/time`, {
      cancelToken: new CancelToken((c) => {
        cancelGetPauseTimeInMinutes = c;
      }),
    });

    return data?.minutes || 0;
  },

  startPause({ suffix, minutes }) {
    return ApiServiceV1.post(`${suffix}/order/process/pause`, { minutes });
  },

  stopPause({ suffix }) {
    return ApiServiceV1.post(`${suffix}/order/process/resume`);
  },
};
