import Wrapper from './_views/Wrapper.vue';

const parentRouteConfig = {
  name: 'Press Sheets Templates',
  to: {
    name: 'profitPressSheetsTemplates',
    query: {
      suffix: '',
      pageNum: '1',
    },
  },
};

export default [
  {
    path: '/profit/press-sheets-templates',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'profitPressSheetsTemplates',
        component: () => import(/* webpackChunkName: "profitPressSheetsTemplates" */ './_views/DsbPressSheetsTemplates.vue'),
        meta: {
          title: 'Press Sheets Templates',
          group: 'profit',
          navigation: {
            name: 'Press Sheets Templates',
            to: {
              name: 'profitPressSheetsTemplates',
              query: {
                suffix: '',
                pageNum: '1',
              },
            },
          },
        },
      },
      {
        path: ':id',
        name: 'profitPressSheetsTemplate',
        component: () => import(/* webpackChunkName: "orderItem" */ './_views/DsbPressSheetsTemplate.vue'),
        meta: {
          title: 'Press Sheets Template',
          parent: parentRouteConfig,
        },
      },
    ],
  },
];
