export const copyToClipboard = async (v, elId) => {
  const container = document.getElementById(elId);
  const element = document.createElement('textarea');

  element.value = v;

  container.appendChild(element);
  element.select();
  document.execCommand('copy');
  container.removeChild(element);

  return true;
};
