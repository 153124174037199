import { LocalStorageService } from './localStorage.service';

const UM_URL_ID = 'userManagerUrl';
const CLIENT_ID = 'clientId';
const API_VERSION_ID = 'apiVersion';

export const AppLocalStorageService = {
  setUMUrl: (url) => LocalStorageService.setItem(UM_URL_ID, url),
  setClientId: (clientId) => LocalStorageService.setItem(CLIENT_ID, clientId),
  setAPIVersion: (version) => LocalStorageService.setItem(API_VERSION_ID, version),

  getUMUrl: () => window.localStorage.getItem(UM_URL_ID) || null,
  getClientId: () => window.localStorage.getItem(CLIENT_ID) || null,
  getAPIVersion: () => window.localStorage.getItem(API_VERSION_ID) || null,
};
