export default [
  {
    path: '/messages/output',
    name: 'messagesOutputMessages',
    component: () => import(/* webpackChunkName: "messagesOutputMessages" */ './_views/DsbOutputMessages.vue'),
    meta: {
      title: 'Output Messages',
      group: 'messages',
      navigation: {
        name: 'Output',
        to: {
          name: 'messagesOutputMessages',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
