<template>
  <v-layout
    align-center
    column
  >
    <slot name="title">
      <strong
        v-show="title"
        :class="$_themeTextColorClass"
      >
        {{ title }}
      </strong>
    </slot>
    <slot :options="{
      chartWidth,
      chartExtend,
      textStyle: {
        color: $_themeTextColor,
      },
    }" />
  </v-layout>
</template>

<script>
import { provideThemeSettings } from '@mixins';

export default {
  name: 'DsbChartWrapper',

  props: {
    title: {
      type: String,
      default: '',
    },
    tooltipHandler: {
      type: Function,
      default: (v) => v,
    },
  },

  mixins: [
    provideThemeSettings,
  ],

  data() {
    return {
      chartWidth: '99%',
    };
  },

  computed: {
    chartExtend() {
      return {
        tooltip: {
          show: true,
          renderMode: 'html',
          formatter: this.onTooltipChange,
        },
      };
    },
  },

  created() {
    setTimeout(() => {
      this.chartWidth = '100%';
    }, 100);
  },

  methods: {
    onTooltipChange(tooltipAxisData) {
      const payload = tooltipAxisData
        .map(({ seriesName: title, marker, value }) => ({ title, marker, value }))
        .sort(({ value: a = 0 }, { value: b = 0 }) => b - a);
      const handled = this.tooltipHandler(payload);
      const tooltip = document.createElement('div');

      // Set tooltip header
      tooltip.innerHTML = `${tooltip.innerHTML}<strong>${tooltipAxisData[0].axisValueLabel}:</strong>`;

      tooltip.style.padding = '5px';

      handled.forEach((v) => {
        tooltip.innerHTML = `${tooltip.innerHTML}<div>${v.marker}${v.title}: ${v.value || '-'}</div>`;
      });

      return tooltip.outerHTML;
    },
  },
};
</script>
