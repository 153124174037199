import { AppLocationsNamespace } from '@store/types';

import { RulesApiService } from './_services/rules.api.service';

import { createRule } from './_models/rule';
import { createRuleSlice } from './_models/ruleSlice';

import { MutationTypes, ActionTypes } from './store.types';

export { namespace } from './store.types';

export default {
  namespaced: true,

  state: {
    rules: [],
    rule: createRule(),
    ruleSlice: createRuleSlice(),
    ruleHistory: [],
    rulesTotalCount: 0,
    ruleHistoryTotalCount: 0,
  },

  mutations: {
    [MutationTypes.SET_RULES](state, payload) {
      state.rules = payload;
    },
    [MutationTypes.SET_RULE](state, payload) {
      state.rule = payload;
    },
    [MutationTypes.SET_RULE_SLICE](state, payload) {
      state.ruleSlice = payload;
    },
    [MutationTypes.SET_RULE_HISTORY](state, payload) {
      state.ruleHistory = payload;
    },
    [MutationTypes.SET_RULES_TOTAL_COUNT](state, payload) {
      state.rulesTotalCount = payload;
    },
    [MutationTypes.SET_RULE_HISTORY_TOTAL_COUNT](state, payload) {
      state.ruleHistoryTotalCount = payload;
    },
  },

  actions: {
    async [ActionTypes.GET_RULES](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items, totalCount } = await RulesApiService.getRules({ suffix, ...params });

      commit(MutationTypes.SET_RULES, items || []);
      commit(MutationTypes.SET_RULES_TOTAL_COUNT, totalCount);
    },

    async [ActionTypes.GET_RULE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_RULE,
        await RulesApiService.getRule({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_RULE_SLICE](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      commit(
        MutationTypes.SET_RULE_SLICE,
        await RulesApiService.getRuleSlice({ suffix, ...params }),
      );
    },

    async [ActionTypes.GET_RULE_HISTORY](
      { commit, rootState: { [AppLocationsNamespace]: l } },
      { suffix = l.suffix, ...params },
    ) {
      const { items, totalCount } = await RulesApiService.getRuleHistory({ suffix, ...params });

      commit(MutationTypes.SET_RULE_HISTORY, items || []);
      commit(MutationTypes.SET_RULE_HISTORY_TOTAL_COUNT, totalCount);
    },
  },
};
