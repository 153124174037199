export const namespace = 'profitSubstrates';

export const MutationTypes = {
  SET_SUBSTRATES: 'SET_SUBSTRATES',
  SET_SUBSTRATES_TOTAL_COUNT: 'SET_SUBSTRATES_TOTAL_COUNT',
};

export const ActionTypes = {
  GET_SUBSTRATES: 'GET_SUBSTRATES',
};
