<template>
  <v-flex sm4>
    <v-select
      v-model="selectedLocation"
      :items="locations"
      class="vbt-hide-form-control-details"
      dark
    />
  </v-flex>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { AppLocationsNamespace, AppLocationsMutationTypes } from '@store/types';

export default {
  name: 'LocationSelector',

  computed: {
    ...mapState(AppLocationsNamespace, {
      suffix: ({ suffix }) => suffix,
      locations: ({ locations }) => locations
        .map(({ name: text, suffix: value }) => ({ text, value })),
    }),

    selectedLocation: {
      get() {
        return this.suffix;
      },
      set(newValue) {
        if (newValue !== this.suffix) {
          this.updateRoute(newValue);
          this.updateLocationSuffix(newValue);
        }
      },
    },
  },

  watch: {
    '$route.query.suffix'(newValue) { //eslint-disable-line
      if (newValue !== this.suffix) {
        this.updateRoute(newValue || this.suffix);
        this.updateLocationSuffix(newValue || this.suffix);
      }
    },
  },

  methods: {
    ...mapMutations(AppLocationsNamespace, {
      updateLocationSuffix(commit, suffix) {
        commit(AppLocationsMutationTypes.SET_SUFFIX, suffix);
      },
    }),

    updateRoute(suffix) {
      const { name, query, meta } = this.$route;

      if (meta?.parent) {
        const to = JSON.parse(
          JSON.stringify(
            meta.parent.to,
          ),
        );

        to.query.suffix = suffix;

        this.$router.push(to, null, () => {});

        return;
      }

      const q = JSON.parse(
        JSON.stringify(
          query,
        ),
      );

      const defaultQuery = JSON.parse(
        JSON.stringify(
          meta?.navigation?.to?.query || meta?.query || {},
        ),
      );

      defaultQuery.suffix = suffix;
      q.suffix = suffix;

      const nextRoute = Object.keys(q).length > 1 ? q : defaultQuery;

      if (nextRoute.pageNum) {
        nextRoute.pageNum = '1';
      }

      this.$router.push({ name, query: nextRoute }, null, () => {});
    },
  },
};
</script>
