export const namespace = 'auth';

export const MutationTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_CODE: 'SET_CODE',
  SET_AUTH: 'SET_AUTH',
  SET_RETURN_URL: 'SET_RETURN_URL',
};

export const ActionTypes = {
  GENERATE_TOKEN_FROM_URL: 'GENERATE_TOKEN_FROM_URL',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};
