<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :close-on-click="closeOnClick"
    nudge-left="265px"
    allow-overflow
    offset-y
  >
    <template #activator="{ on }">
      <v-badge
        :value="notificationsCount"
        :content="notificationsCount"
        color="error"
        overlap
      >
        <v-btn
          icon
          small
          color="white"
          v-on="on"
        >
          <v-icon>
            notifications
          </v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card class="dsb-notifications-wrapper">
      <v-card-text>
        <v-layout
          style="position: absolute; margin-top: 12px; width: 100%; padding-right: 55px;"
          justify-end
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                :loading="isSyncLoading"
                color="success"
                small
                icon
                v-on="on"
                @click="syncNotifications"
              >
                <v-icon>
                  sync
                </v-icon>
              </v-btn>
            </template>
            Sync notifications
          </v-tooltip>
        </v-layout>
        <vbt-table
          v-if="notificationsCount"
          :headers="headers"
          :items="notifications"
          :page-size="5"
          show-paginator
        >
          <template #basketId="{ item }">
            <router-link
              :to="{
                name: 'pressSheets',
                query: {
                  suffix,
                  pageNum: '1',
                  basketId: item.basketId,
                },
              }"
              class="info--text"
              target="_blank"
            >
              {{ item.basketId }}
            </router-link>
          </template>

          <template #created="{ item }">
            {{ getDatesDifference(item.created).formatString({ second: false }) }} ago
          </template>

          <template #actions="{ item }">
            <v-btn
              :loading="$_checkItemInLoadingSpinners(item.id)"
              icon
              class="mr-1"
              color="error"
              @click="removeNotification(item.id)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
        </vbt-table>

        <v-layout
          v-else
          justify-center
        >
          No notifications yet
        </v-layout>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';
import { multiLoadingSpinner } from '@mixins';

import { createTicker, getDatesDifference, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import {
  namespace as NotificationsNamespace,
  ActionTypes as NotificationsActionTypes,
} from '../store.types';

import { NotificationsApiService } from '../_services/notifications.api.service';

const dataLoadingOptions = {
  dependenciesChangeHandler: 'resetTicker',
  getterName: NotificationsActionTypes.GET_NOTIFICATIONS,
  searchable: false,
  paged: false,
};

const headers = Object.freeze([
  { text: 'Basket ID', value: 'basketId' },
  { text: 'Created', value: 'created' },
  { text: '', value: 'actions', width: '10px' },
]);

const notificationsTicker = createTicker();

export default {
  name: 'DsbNotificationsWidget',

  mixins: [
    useDataLoading(dataLoadingOptions),
    multiLoadingSpinner,
  ],

  data() {
    return {
      headers,

      showMenu: false,
      closeOnClick: true,

      isSyncLoading: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapState(NotificationsNamespace, ['notifications']),

    notificationsCount() {
      return this.notifications.length;
    },
  },

  created() {
    notificationsTicker.subscribeToTicker(this[NotificationsActionTypes.GET_NOTIFICATIONS]);
    notificationsTicker.loadTicker({ intervalMSec: 60000 });
  },

  beforeDestroy() {
    notificationsTicker.destroyTicker();
  },

  methods: {
    ...mapActions(NotificationsNamespace, [NotificationsActionTypes.GET_NOTIFICATIONS]),

    removeNotification(id) {
      this.closeOnClick = false;

      this.$_addItemToLoadingSpinners(id);

      this.wrapToLoadingFn({
        req: NotificationsApiService.removeNotification.bind({}, {
          suffix: this.suffix,
          id,
        }),
        onSuccess: async () => {
          this.closeOnClick = true;

          this.resetTicker();

          try {
            await this.$_getData();
          } finally {
            this.$_removeItemFromLoadingSpinners(id);
          }
        },
        onReject: (e) => {
          this.closeOnClick = true;

          if (e) {
            this.$VBlackerTheme.notification.error(e);
          }
        },
      });
    },

    syncNotifications() {
      this.wrapToLoadingFn({
        req: NotificationsApiService.syncNotifications.bind({}, { suffix: this.suffix }),
        loadingFlagName: 'isSyncLoading',
        onSuccess: () => this.$VBlackerTheme.notification.success('Successfully synced'),
      });
    },

    resetTicker() {
      notificationsTicker.stopTicker();
      notificationsTicker.loadTicker({ intervalMSec: 60000 });
    },

    getDatesDifference,
    wrapToLoadingFn,
  },
};
</script>

<style lang="css" scoped>
.dsb-notifications-wrapper {
  margin-top: 10px;
  width: 530px;
}
</style>
