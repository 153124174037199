import Vue from 'vue';

import VBlackerTheme, { VBlackerThemeProvider } from 'v-blacker-theme';

import { createRouter } from '@router';

import store from '@store';

import DsbShortTextView from '@components/ui/DsbShortTextView.vue';
import DsbPasswordInput from '@components/ui/DsbPasswordInput.vue';
import DsbChartWrapper from '@components/ui/DsbChartWrapper.vue';
import DsbStateSwitch from '@components/ui/DsbStateSwitch.vue';
import DsbEntityBadge from '@components/ui/DsbEntityBadge.vue';
import DsbStateIcon from '@components/ui/DsbStateIcon.vue';
import DsbLabel from '@components/ui/DsbLabel.vue';
import DsbLink from '@components/ui/DsbLink.vue';

import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(VBlackerTheme);

Vue.component('dsb-short-text-view', DsbShortTextView);
Vue.component('dsb-password-input', DsbPasswordInput);
Vue.component('dsb-chart-wrapper', DsbChartWrapper);
Vue.component('dsb-state-switch', DsbStateSwitch);
Vue.component('dsb-entity-badge', DsbEntityBadge);
Vue.component('dsb-state-icon', DsbStateIcon);
Vue.component('dsb-label', DsbLabel);
Vue.component('dsb-link', DsbLink);

const router = createRouter();

const app = new Vue({
  store,
  router,
  ...VBlackerThemeProvider,
  render: (h) => h(App),
});

router.onReady(() => app.$mount('#app'));
