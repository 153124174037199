export default [
  {
    path: '/profit/machines',
    name: 'profitMachines',
    component: () => import(/* webpackChunkName: "profitMachines" */ './_views/DsbMachines.vue'),
    meta: {
      title: 'Machines',
      group: 'profit',
      navigation: {
        name: 'Machines',
        to: {
          name: 'profitMachines',
          query: {
            suffix: '',
            pageNum: '1',
          },
        },
      },
    },
  },
];
